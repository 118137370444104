import React from "react";
import { useParams } from "react-router-dom";
import MainSection from "../sections/home/MainSection";
import ProfileSection from "../sections/home/ProfileSection";
import Wise1Section from "../sections/home/Wise1Section";
import Wise2Section from "../sections/home/Wise2Section";
import { ScrollingProvider } from "../context/ScrollingContext";
import { getYearPlaceHolder } from "../services/yearSevices";
import CoursesSection from "../sections/home/CoursesSection/CoursesSection";

const Year = () => {
    const { id } = useParams();

    return (
        <>
            <MainSection title={getYearPlaceHolder(id)} />
            <ProfileSection />
            <ScrollingProvider>
                <Wise1Section />
            </ScrollingProvider>
            <CoursesSection title={getYearPlaceHolder(id)} year={id} />
            <ScrollingProvider>
                <Wise2Section />
            </ScrollingProvider>
        </>
    );
};

export default Year;
