import React, { useEffect, useState } from "react";
import LoadingIcon from "../../../components/ui/LoadingIcon";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import SubjectCard from "../../../components/ui/SubjectCard";

const SubjectsGrid = ({ department_id = 0, teacherId = 0, isTeacher = false }) => {
    const [subjects, setSubjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const getSubjects = async () => {
        setIsLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        // const { data } = await http.get(`api/departments/${department_id}/subjects`, config);

        let result;

        if (isTeacher) {
            result = await http.get(`api/teachers/${teacherId}/subjects`, config);
        } else {
            result = await http.get(`api/departments/${department_id}/subjects`, config);
        }

        const { data } = result;

        setSubjects([]);
        setSubjects(data);
        setIsLoading(false);
        // console.log(data);
    };
    useEffect(() => {
        if (department_id > 0 || teacherId > 0) {
            getSubjects();
        }
    }, [department_id, teacherId]);

    return (
        <>
            {isLoading ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md border-blue-300 dark:border-blue-500 p-10 bg-blue-100 dark:bg-opacity-5 smooth clr-text-primary flex space-x-2 space-x-reverse">
                        <LoadingIcon className="font-h1 text-blue-500" />
                        <span>يتم الآن تحميل المواد ...</span>
                    </div>
                </div>
            ) : subjects.length < 1 ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md border-rose-300 dark:border-rose-500 p-10 bg-rose-100 dark:bg-opacity-5 smooth clr-text-primary">
                        سيتم اضافة مواد داخل هذه الشبعة قريبًا
                    </div>
                </div>
            ) : (
                <div
                    className={`g-teal-400 smooth clr-text-primary drk:bg-teal-800 bg-opacity-50 dark:bg-opacity-50 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pb-10 gap-4 items-cent`}
                >
                    {subjects.map((subject) => {
                        return <SubjectCard key={subject.id} {...subject} />;
                    })}
                </div>
            )}
        </>
    );
};

export default SubjectsGrid;
