import React, { useContext, useEffect, useState } from "react";
import ScrollingContext from "../../context/ScrollingContext";

import "./Wise2Section.css";
import {
    getCurrentScrollingPosition,
    getScrollingPercentage,
} from "../../services/scrollingServices";
import Container from "../../components/ui/Container";

const Wise2Section = () => {
    const [toUsePercentage, setToUsePercentage] = useState(0);

    const { scrolling } = useContext(ScrollingContext);

    const handleScrolling = () => {
        const scrollTop = getCurrentScrollingPosition();
        const triangleContainer = document.querySelector(
            ".home__wise-2 .home-wise-triangle__contianer"
        );
        const containerHeight = triangleContainer.getBoundingClientRect().height;
        const offsetTop = triangleContainer.getBoundingClientRect().top + scrollTop;

        const sConstant = (containerHeight * 2) / 3;
        const eConstant = (window.innerHeight - containerHeight) / 2 - 50;

        const { percentage } = getScrollingPercentage(
            scrollTop,
            offsetTop,
            containerHeight,
            sConstant,
            eConstant,
            false
        );
        setToUsePercentage(percentage);
    };

    const getTextPercentage = () => {
        const textEndPoint = 0.6;

        const textPercentage =
            (toUsePercentage >= textEndPoint ? textEndPoint : toUsePercentage) / textEndPoint;
        return 1 - textPercentage;
    };
    const toTransform = 90;

    useEffect(() => {
        handleScrolling();
    }, [scrolling]);

    return (
        <>
            <div className="home__wise home__wise-2">
                <div className="home-wise__rect"></div>
            </div>
            <section className="home__wise home__wise-2 py-5 relative">
                <div className="home-wise__rect"></div>
                <div className="home-wise__rect"></div>
                <Container className="py-5">
                    <div className="home-wise__container">
                        <div className="home-wise-triangle__contianer w-full max-w-lg">
                            <svg className="home-wise__triangle2" viewBox="0 0 520 500">
                                <path
                                    className="triangle triangle-top smooth-fast"
                                    style={{ strokeDashoffset: `${803.6 * (1 - toUsePercentage)}` }}
                                    d="M206.1 86.2 171.9 27 24.1 283h295.6l-72-124.7"
                                />
                                <path
                                    style={{ strokeDashoffset: `${803.6 * (1 - toUsePercentage)}` }}
                                    className="triangle triangle-bottom smooth-fast"
                                    d="m306.8 408 36.9 64 147.7-256H195.9l70.3 121.8"
                                />
                                <path
                                    className="hide smooth"
                                    d="M180 78h90v90h-90zM240 328h90v90h-90z"
                                />
                                <g
                                    className="triangle-text__container-1"
                                    style={{
                                        transform: `translateX(${
                                            getTextPercentage() * toTransform * -1
                                        }px)`,
                                    }}
                                >
                                    <text
                                        x={420}
                                        y={137.444}
                                        className="triangle-text triangle-text-1 smooth"
                                        data-svg-origin="183.71875 79.1875"
                                    >
                                        {
                                            "\u201D\u0645\u0627 \u062A\u0631\u0627\u062C\u0639 \u0633\u0647\u0645 \u0625\u0644\u0627 "
                                        }
                                    </text>
                                </g>
                                <g
                                    className="triangle-text__container-2"
                                    style={{
                                        transform: `translateX(${
                                            getTextPercentage() * toTransform
                                        }px)`,
                                    }}
                                >
                                    <text
                                        x={370}
                                        y={380}
                                        className="triangle-text triangle-text-2 smooth"
                                        data-svg-origin="-2.078125 298.046875"
                                    >
                                        {
                                            "\u0644\u064A\u0646\u0637\u0644\u0642 \u0641\u0643\u0646 \u0633\u0647\u0645\u0627\u201C"
                                        }
                                    </text>
                                </g>
                            </svg>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Wise2Section;
