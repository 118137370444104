import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { adminPath } from "../../../services/defaultSettings";
import CenterIcon from "../../../components/ui/CenterIcon";
function isAfterFeb132024(time) {
    // Create February 13, 2024, at 11:59 PM
    const feb132024 = new Date(2024, 1, 13, 23, 59, 59); // Note: Month in JavaScript Date starts from 0, so 1 represents February

    // // Create February 13, 2024, at 11:59 PM
    // const feb132024 = new Date(2024, 1, 13, 23, 59, 59); // Note: Month in JavaScript Date starts from 0, so 1 represents February

    // Check if the given time is after February 13, 2024, at 11:59 PM
    return time.getTime() > feb132024.getTime();
}

const MainNotification = () => {
    const [isHidden, setIsHidden] = useState(true);

    const [className, setClassName] = useState("");

    const location = useLocation();

    useEffect(() => {
        const currentTime = new Date();

        setIsHidden(!isAfterFeb132024(currentTime));

        // if (location.pathname.includes(adminPath)) {
        //     setClassName(" !hidden");
        // } else {
        //     setClassName("");
        // }
        if (location.pathname !== "/home" && location.pathname !== "/") {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location]);

    return (
        <>
            <div
                className={`fixed w-full top-[5rem] right-0 left-0 px-2 py-2 ${className} ${
                    isHidden ? "!hidden" : ""
                }`}
            >
                <div className="relative rounded-md bg-sky-800 bg-opacity-90 dark:bg-sky-500 dark:bg-opacity-80 smooth text-white p-5 text-center">
                    <div className="flex-center-both flex-col space-y-2">
                        {/* <div className="font-w-bold font-h2 underline">خلي بالك</div> */}
                        <div className="">
                            لو عندك أي استفسار او سؤال علمي تواصل معنا من خلال الشات بوت ‼️
                            <br />
                            <a
                                href="https://t.me/PhysicsSocietySupportBot"
                                target="_blank"
                                className="underline"
                                rel="noreferrer"
                            >
                                https://t.me/PhysicsSocietySupportBot
                            </a>
                        </div>
                    </div>
                    <button
                        className="absolute left-2 top-2 pt-0.5 flex-center-both rounded-md  bg-sky-800 font-h3 hover-shadow smooth"
                        onClick={() => setIsHidden(true)}
                    >
                        <span className="p-2">
                            <CenterIcon icon="fa:close" />
                        </span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default MainNotification;
