import React from "react";
import MahmoudMagdy from "../../assets/MagdyImage.png";

import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
import { ReactSVG } from "react-svg";
import Prize from "../../assets/prize.png";
import Magentic from "../../assets/magnetic.svg";
import Nucleus from "../../assets/Nucleus.svg";
import NucleusTitle from "../../assets/nucleustitle.svg";

const AwardsSecction = () => {
    const CtaMap = [
        {
            firstWord: "شرح وحل",
            cta: "بأكثر من",
            lastWord: "طريقة",
            center: false,
        },
        {
            firstWord: "أكثر من",
            cta: "+950,000",
            lastWord: "طالب",
            center: true,
        },
        {
            firstWord: "دعم فني",
            cta: "24/7",
            lastWord: "معاك",
            center: false,
        },
    ];
    const token = auth.getToken();
    return (
        <>
            <section className=" min-h-screen md:min-h-[700px] relative z-10 w-full overflow-hidden flex-center-both">
                <div className="container px-4 lg:px-16 flex justify-center items-center">
                    <div className="relative flex-center-both space-y-10 my-10 flex-col md:flex-row">
                        <div className="group basis-full md:basis-1/2 flex items-center md:items-start justify-center md:justify-start flex-col space-y-5  ">
                            <h1 className="flex items-center font-vip justify-center md:justify-start font-vip gap-4 text-4xl md:text-5xl text-[#303506] dark:text-[#C3D14F] ">
                                <span className="w-10 md:w-[80px] ">
                                    <ReactSVG
                                        src={NucleusTitle}
                                        className="w-full h-auto animate-spin "
                                    />
                                </span>
                                ذاكر واكسب
                            </h1>
                            <p className=" w-full sm:w-3/4 text-center md:text-right xl:w-full text-lg dark:text-[#CCCBCB] md:text-2xl">
                                وفرنالك اجواء تنافسية بينك وبين زمايلك عشان نحفزك تقفل .. لو طلعت من
                                الأوائل في امتحانات اينشتاين هتفوز بجوايز مش هتخطر على بالك .. هنروق
                                على الأوائل المتفوقين
                            </p>
                            <div className=" flex pt-6  flex-col gap-10">
                                <div>
                                    <Button
                                        color=""
                                        className="scale text-xl border-none px-6 py-3 font-vip text-midNight-950 bg-Olive-400"
                                        element="Link"
                                        to={`${token ? "/me/user/courses" : "/register"}`}
                                    >
                                        {token ? "ذاكر وأستعد" : "سجل وجرب"}
                                    </Button>
                                </div>
                                <span className="w-[200px] group-hover:animate-pulse absolute bottom-0 right-1/4  hidden xl:inline-block ">
                                    <img src={Prize} alt="Magdy##Prize" />
                                </span>
                            </div>
                        </div>
                        <div className="flex basis-full md:basis-1/2 flex-center-both">
                            <div className="relative flex-center-both max-w-[600px] h-full">
                                <img className="  h-auto " src={MahmoudMagdy} alt="MahmoudMagdy" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative z-20 bg-Olive-50 dark:bg-midNight-900 smooth py-7 px-4 sm:p-8 sm:px-16">
                <div className=" flex items-center justify-between space-y-10 md:space-y-0 flex-wrap gap-6 xl:gap-0 flex-col xl:flex-row ">
                    {CtaMap.map((item, index) => (
                        <div
                            className={` ${
                                item.center ? "relative h-full mt:0 xl:mt-20" : "relative h-fit"
                            } `}
                        >
                            <div className="absolute z-10 inset-0 flex items-center justify-center">
                                <p className=" text-center text-2xl md:text-4xl   clr-text-primary smooth">
                                    {item.firstWord}
                                    <br />
                                    <span className="text-Olive-700 text-3xl md:text-5xl my-3 font-bold inline-block dark:text-Olive-200 smooth">
                                        {" "}
                                        {item.cta}
                                    </span>
                                    <br />
                                    {item.lastWord}
                                </p>
                            </div>
                            <span className="opacity-10 dark:opacity-60 smooth ">
                                <ReactSVG
                                    src={Nucleus}
                                    className="w-[200px] animate-pulse md:w-[350px] "
                                />
                            </span>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
};

export default AwardsSecction;
