import React from "react";
import Container from "../../components/ui/Container";

import profile from "../../assets/profile.png";
import Waves from "../../components/ui/Waves";

// import bg from "../../assets/bg-arabic.png";
import bg2 from "../../assets/bg-arabic2.png";

const ProfileSection = () => {
    return (
        <div className="bg-yellow-300 dark:bg-yellow-200 smooth text-slate-900 relative">
            <div
                className="inset-0 absolute w-full h-full overflow-hidden opacity-10  smooth"
                style={{
                    backgroundImage: "url(" + bg2 + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center top",
                    backgroundRepeat: "repeat-y",
                }}
            >
                {/* <img src={bg} alt="bg" /> */}
                <div className="absolute inset-0 w-full h-full bg-gradient-bg smooth"></div>
            </div>
            <Container className="relative z-10">
                <div className="flex sm:flex-row flex-col sm:space-x-5 sm:space-x-reverse sm:space-y-0 space-y-10">
                    <div className="sm:basis-1/2 h-full flex-center-both">
                        <div className="w-full max-w-md">
                            <img src={profile} alt="profile" />
                        </div>
                    </div>
                    <div className="sm:basis-1/2 flex-center-both">
                        <div className="w-full h-full flex justify-center items-center sm:items-start flex-col font-taj">
                            <div>
                                <div className="font-w-bold font-h2 text-cyan-700">معلمك</div>
                                <div className="font-w-bold font-bigmax font-taj">
                                    وَلِيْد مُحْسِن
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Waves className="fill-primary-container transform origin-bottom scale-y-50 md:scale-y-100" />
        </div>
    );
};

export default ProfileSection;
