import React from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import { ChartData, PieChart } from "../../components/ui/ChartData";
import { isMultiYear } from "../../services/defaultSettings";

const AdminAnalytics = () => {
    return (
        <AdminContainer>
            <ChartData />
            {isMultiYear ? <PieChart /> : ""}
        </AdminContainer>
    );
};

export default AdminAnalytics;
