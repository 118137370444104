import React, { createContext, useState } from "react";

const SubjectTeacherContext = createContext();

export const SubjectTeacherProvider = ({ children }) => {
    const [subjectTeacher, setSubjectTeacher] = useState({});

    return (
        <SubjectTeacherContext.Provider
            value={{
                subjectTeacher,
                setSubjectTeacher,
            }}
        >
            {children}
        </SubjectTeacherContext.Provider>
    );
};

export default SubjectTeacherContext;
