import React, { useContext, useEffect, useState } from "react";
import ScrollingContext from "../../context/ScrollingContext";
import {
    easeOutSine,
    getPartialPercentage,
    getScrollingPercentage,
} from "../../services/scrollingServices";
import { changeGradient } from "../../utils/ui";

import "./Electric.css";

const Electric = () => {
    // const [percentage, setPercentage] = useState(0);
    const [pathes, setPathes] = useState([]);

    const { scrolling } = useContext(ScrollingContext);

    const animateElectric = () => {
        const windowHeight = window.innerHeight;

        const mainSection = document.querySelector(".home-main__section");
        const electric = document.querySelector(".electric");
        const container = document.querySelector(".home-main__container");
        const text = document.querySelector(".home-main__text");
        const title = document.querySelector(".home-main__title");

        const height = mainSection.getBoundingClientRect().height;

        const { percentage: passingPercentage } = getScrollingPercentage(
            scrolling,
            0,
            height,
            windowHeight,
            0,
            true
        );

        pathes.forEach((path) => {
            var length = path.getTotalLength();
            path.style.strokeDashoffset = (1 - passingPercentage) * length;
        });
        if (passingPercentage !== 1) {
            electric.classList.remove("static");
            container.classList.remove("static");
        } else {
            electric.classList.add("static");
            container.classList.add("static");
        }

        const movingTextPercentage =
            1 - easeOutSine(getPartialPercentage(1 - passingPercentage, 0.4, 0.95));
        const movingTitlePercentage =
            1 - easeOutSine(getPartialPercentage(1 - passingPercentage, 0, 0.8));

        const transformed = 200;

        text.style.transform = `translateY(${transformed * movingTextPercentage}px) scale(${
            1 + 0.2 * (1 - movingTextPercentage)
        })`;
        text.style.opacity = 1 - movingTextPercentage;

        title.style.transform = `translateY(${transformed * movingTitlePercentage}px) scale(${
            1 + 0.2 * (1 - movingTitlePercentage)
        })`;
        title.style.opacity = 1 - movingTitlePercentage;

        const rgb = [
            [
                [9, 71, 219],
                [7, 216, 157],
            ],
            [
                [157, 49, 110],
                [222, 45, 62],
            ],
        ];
        const gradient = changeGradient(1 - passingPercentage, rgb);
        const arrows = document.querySelectorAll(".arrow span");
        arrows.forEach((element) => {
            element.style.borderImageSource = gradient;
        });
    };
    const initAnimating = () => {
        const paths = document.querySelectorAll(".electric .st0");
        paths.forEach((path) => {
            var length = path.getTotalLength();
            path.style.transition = path.style.WebkitTransition = "none";
            path.style.strokeDasharray = length + " " + length;
            path.style.strokeDashoffset = length;
        });
        setPathes(paths);
    };

    useEffect(() => {
        initAnimating();
    }, []);

    useEffect(() => {
        animateElectric();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrolling]);
    return (
        <svg
            className="electric relative z-0 inset-x-0 w-full"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 960 560"
            style={{
                objectFit: "cover",
            }}
            xmlSpace="preserve"
            width="100vw"
            height="100vh"
            preserveAspectRatio="xMidYMid slice"
        >
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "315.878,315.878",
                    strokeDashoffset: 0.724491,
                }}
                d="M846.2.1 751.5 67v115L720 203.5h-33l-10 9.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "136.865,136.865",
                    strokeDashoffset: 0.313911,
                }}
                d="M868.2.2 761.5 73.7V81"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "136.405,136.405",
                    strokeDashoffset: 0.312855,
                }}
                d="m890.8-.3-93.1 67.8H792l-12.8 8.8"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "341.491,341.491",
                    strokeDashoffset: 0.783235,
                }}
                d="M698 225.5h15l53.5-37.8V100L908.3.4"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "109.013,109.013",
                    strokeDashoffset: 0.25003,
                }}
                d="m799.2 97.3-10.7 9.2V190l-9 7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "170.476,170.476",
                    strokeDashoffset: 0.391001,
                }}
                d="M823.5 107v-4L960 7.7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "234.022,234.022",
                    strokeDashoffset: 0.536747,
                }}
                d="M960 28.8 813.5 132.2l-2 40.8v3l-8.3 7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "154.214,154.214",
                    strokeDashoffset: 0.353702,
                }}
                d="m959.6 57.3-6.1 3V68l-115.1 79.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "124.204,124.204",
                    strokeDashoffset: 0.284873,
                }}
                d="M959.6 81 857 151"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "333.248,333.248",
                    strokeDashoffset: 0.764329,
                }}
                d="M677 261.5h50l226.7-158.7 6.5-.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "241.393,241.393",
                    strokeDashoffset: 0.553654,
                }}
                d="m732.2 331.9 4.3-2.9v-6l8.5-6.5h182l16.5 13H960"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "104,104",
                    strokeDashoffset: 0.238532,
                }}
                d="M772 331.5h104"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "140.404,140.404",
                    strokeDashoffset: 0.322027,
                }}
                d="M902 329.5h-4l-13 8H772l-5.5 6"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "79,79",
                    strokeDashoffset: 0.181193,
                }}
                d="M789 343.5h79"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "188.506,188.506",
                    strokeDashoffset: 0.432353,
                }}
                d="m663.2 374.8 10.8-7.3h11l6.5-3H742l13-9h91"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "295.385,295.385",
                    strokeDashoffset: 0.677488,
                }}
                d="m669.5 383.5 5-5H723l7.3 7H960"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "74.7212,74.7212",
                    strokeDashoffset: 0.171379,
                }}
                d="M886 371.5h18l4.3 3.3 51.3-.3"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "297.984,297.984",
                    strokeDashoffset: 0.68345,
                }}
                d="m677.2 394.3-2.7-3.8V386l5-3.5H720l7 6h233"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "244.591,244.591",
                    strokeDashoffset: 0.560988,
                }}
                d="m716.2 388.9 7.8 3.6h236"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "235.34,235.34",
                    strokeDashoffset: 0.539771,
                }}
                d="m726.9 404 3.4-4.5H960"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "260.511,260.511",
                    strokeDashoffset: 0.597503,
                }}
                d="M702 408.5h25l8.5-7H960"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "296.976,296.976",
                    strokeDashoffset: 0.681138,
                }}
                d="m667.2 409.5 9.8 6h61l6-6h216"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "199.547,199.547",
                    strokeDashoffset: 0.457677,
                }}
                d="m763.5 417.5 3-5 193.7 2.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "299.064,299.064",
                    strokeDashoffset: 0.685927,
                }}
                d="m667.1 429.2 7.4-4.7H727l11 7h34l7.5-7H960"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "317.869,317.869",
                    strokeDashoffset: 0.729058,
                }}
                d="m650.9 434.9 6.1 2.6h24l13-6h26l10.3 6H843l21.5 16H960"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "98.5,98.5",
                    strokeDashoffset: 0.225917,
                }}
                d="m862 446 6 2.5h92"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "84,84",
                    strokeDashoffset: 0.192661,
                }}
                d="M876 442.5h84"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "105.211,105.211",
                    strokeDashoffset: 0.24131,
                }}
                d="M856 434.5h6l6 4h92"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "169.988,169.988",
                    strokeDashoffset: 0.389881,
                }}
                d="m751.5 258.8-3 2.7v4.5l6.1 3.5H776l79.8-56 30.2-.7 4.8-2.3"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "44.0861,44.0861",
                    strokeDashoffset: 0.101115,
                }}
                d="M919 188.5h15l5 4h13l7.6-6"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "182.761,182.761",
                    strokeDashoffset: 0.419177,
                }}
                d="m959.1 193.3-4.1 4.2h-19l-4-3h-67l-63.6 46H794"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "96.7218,96.7218",
                    strokeDashoffset: 0.221839,
                }}
                d="m766.4 248.2 17.1-11.7H801l43.5-32V200"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "164.826,164.826",
                    strokeDashoffset: 0.378041,
                }}
                d="m815.2 261.6 8.9-6.1-.5-7.1 38.2-25.9H929l14.5-9H960"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "137.016,137.016",
                    strokeDashoffset: 0.314257,
                }}
                d="m832.5 258-.3 5.5 6.2 6H872l7-4 81.2-1.7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "147.559,147.559",
                    strokeDashoffset: 0.338437,
                }}
                d="M849 253.5h-5l-5.5-3V246l27.3-20.5H935l10-9h15"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "90,90",
                    strokeDashoffset: 0.206422,
                }}
                d="M870 241.5h90"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "87.4031,87.4031",
                    strokeDashoffset: 0.200466,
                }}
                d="M875 249.5h71l4 5h10"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "116.09,116.09",
                    strokeDashoffset: 0.266262,
                }}
                d="m803.7 269.5 5.3 3h110"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "192.576,192.576",
                    strokeDashoffset: 0.441688,
                }}
                d="m495 383 8.5 2.5V418l9 6.5V437l-11 9v79l11 8.5H533"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "311.359,311.359",
                    strokeDashoffset: 0.714125,
                }}
                d="m517.2 421.2 5.3 3.3V443l-9 5.5V485l17 11.5V516l16 10.5H670l46 33.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "193.084,193.084",
                    strokeDashoffset: 0.442853,
                }}
                d="m508.9 560 24.1-17.5h134l23.5 17.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "132.245,132.245",
                    strokeDashoffset: 0.303314,
                }}
                d="m535 560.2 8-5.7h114l6.2 5.7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "262.942,262.942",
                    strokeDashoffset: 0.603079,
                }}
                d="M525.5 454v24l36 26.5v8.5l8.4 6.5H674l58.2 40.7"
            />
            <path
                className="st0"
                d="m536.6 410.2 6.9 5.2V466s44.5 33.5 46.5 33.5h130l10.5 7.6V539l29 21"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "327.254,327.254",
                    strokeDashoffset: 0.750583,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "85.4296,85.4296",
                    strokeDashoffset: 0.19594,
                }}
                d="m748.5 496 .5 37.5 39.8 26.7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "37.0206,37.0206",
                    strokeDashoffset: 0.0849097,
                }}
                d="m794.1 504 12.9 9.5h21"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "443.415,443.415",
                    strokeDashoffset: 1.01701,
                }}
                d="M567.5 431v23l42.5 28.5h205l87.5 61.8 57.1 1.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "191.047,191.047",
                    strokeDashoffset: 0.438182,
                }}
                d="m610.2 458.2 18.7 13.3H797"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "352.01,352.01",
                    strokeDashoffset: 0.807362,
                }}
                d="m635.5 421.4 7 3.1V439l5.6 3.5H698l9.5-8H722l8.3 8H842l22 16h96"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "136,136",
                    strokeDashoffset: 0.311927,
                }}
                d="M485.5 421v136"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "156.927,156.927",
                    strokeDashoffset: 0.359924,
                }}
                d="m485.2 409.4-7.7 6.1V431l-5 3.5V560"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "122,122",
                    strokeDashoffset: 0.279817,
                }}
                d="M465.5 438v122"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "164.385,164.385",
                    strokeDashoffset: 0.37703,
                }}
                d="m441.5 399.4 21 15.6v9l-4.5 4 1 123.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "133.768,133.768",
                    strokeDashoffset: 0.306806,
                }}
                d="M437.5 421v107L415 542.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "162.279,162.279",
                    strokeDashoffset: 0.372199,
                }}
                d="M387.5 560v-14l43-29.5V428l-6.5-4"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "183.623,183.623",
                    strokeDashoffset: 0.421153,
                }}
                d="m405.8 410.2-9.3 5.3v9.5l12 9.5V494l-44 31.5V560"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "195.572,195.572",
                    strokeDashoffset: 0.448559,
                }}
                d="m404.8 399.6-12.3 8.9V429l9 5.5.3 45.2-43.3 27.4V560"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "20.2272,20.2272",
                    strokeDashoffset: 0.0463927,
                }}
                d="m307 551.2 16.5-11.7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "57.0306,57.0306",
                    strokeDashoffset: 0.130804,
                }}
                d="m288.5 551.2 41-29.6 6 2.4"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "186.542,186.542",
                    strokeDashoffset: 0.427849,
                }}
                d="m390.5 400.1-4 1.7V435l4 3v40l-42 29.1V560"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "209.122,209.122",
                    strokeDashoffset: 0.479639,
                }}
                d="M374.5 413v20l-3 1.5V467l-116 81.5V560"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "198.375,198.375",
                    strokeDashoffset: 0.454988,
                }}
                d="M360.5 424v4l-8 4.5V462l-116 80.5V557"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "240.207,240.207",
                    strokeDashoffset: 0.550933,
                }}
                d="m189.8 560.6 32.7-24.1h8.5l110.5-78V433l7-5v-15l5-4.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "212.836,212.836",
                    strokeDashoffset: 0.488157,
                }}
                d="m162 560.8 52.5-36.3h8.5L323.5 451v-16"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "253.163,253.163",
                    strokeDashoffset: 0.580649,
                }}
                d="m132.5 560.3 71.5-48.8h7l95.5-68.6V421l16.5-11.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "403.342,403.342",
                    strokeDashoffset: 0.925097,
                }}
                d="m345.2 397-4.2-4.5h-13l-16 12h-18l-27.5 16.9V435l-154 107.5H0"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "65.1008,65.1008",
                    strokeDashoffset: 0.149314,
                }}
                d="M259 451.5h-7l-43.5 33v3.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "94.9028,94.9028",
                    strokeDashoffset: 0.217667,
                }}
                d="m93 560-7-5.5-86-.3"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "298.733,298.733",
                    strokeDashoffset: 0.685168,
                }}
                d="m255.2 419.5-12.7 9V440L107 533.5 0 536.4"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "84.666,84.666",
                    strokeDashoffset: 0.194188,
                }}
                d="M164.5 473v7l-64 44"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "46.3019,46.3019",
                    strokeDashoffset: 0.106197,
                }}
                d="M81.5 522v-11l24-15v-7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "49.1213,49.1213",
                    strokeDashoffset: 0.112664,
                }}
                d="M138.5 480v5L103 511.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "35.6826,35.6826",
                    strokeDashoffset: 0.0818407,
                }}
                d="m105.5 471.5-9-1.7-15 11.7v7.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "56.316,56.316",
                    strokeDashoffset: 0.129165,
                }}
                d="m84 495.6 36.8-26.1H132"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "294.976,294.976",
                    strokeDashoffset: 0.67655,
                }}
                d="M0 507.5h21L38.5 494v-28L74 442.5h86l59.5-44.5v-21"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "325.316,325.316",
                    strokeDashoffset: 0.746137,
                }}
                d="M0 520.5h30l28.5-19.8V473l25.2-17.5H168l83.5-58.2V390l7.5-4.7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "346.947,346.947",
                    strokeDashoffset: 0.795749,
                }}
                d="M0 527.5h37l30.5-24V476l21.2-13.5H177l76-55h11l10.9-8.1 1.6-11.7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "33.2402,33.2402",
                    strokeDashoffset: 0.076239,
                }}
                d="m194.5 353.8 11.2-8.3H225"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "284.305,284.305",
                    strokeDashoffset: 0.652076,
                }}
                d="M154 328.5h5.8l32.2-24h73.3l8.7-5h28l5.4-5 .1-1.5v-68l-5-4v-18l-3.5-4.5h-14l-4.7-3.8"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "84.791,84.791",
                    strokeDashoffset: 0.194475,
                }}
                d="M0 285.5h27l41.5-29.8V249"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "47.4142,47.4142",
                    strokeDashoffset: 0.108748,
                }}
                d="m86.3 276.7 34.5-23.4 1.2-5.6"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "49.4462,49.4462",
                    strokeDashoffset: 0.113409,
                }}
                d="m135.3 249-.1 4.7L99 280"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "14.7944,14.7944",
                    strokeDashoffset: 0.0339321,
                }}
                d="M0 337.5h5l8.3 5.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "129.89,129.89",
                    strokeDashoffset: 0.297913,
                }}
                d="m.6 356 21.6.4 8.3-6.6 2-1.2V317l13.5-9.5h39l6.7-4.8"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "158.715,158.715",
                    strokeDashoffset: 0.364025,
                }}
                d="m60.2 329.8 18.6 14.7H103l56.8-40H173l17-11.5 7.5-1.3"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "174.456,174.456",
                    strokeDashoffset: 0.400129,
                }}
                d="m295.3 240.7 6.2 3.1V277l-7.6 6.5H186l-11.5 8.2v2.3"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "76.1188,76.1188",
                    strokeDashoffset: 0.174584,
                }}
                d="M291 260.5h-19l-9.3 7H255l-7-5h-26l-2.8 1.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "94.6284,94.6284",
                    strokeDashoffset: 0.217038,
                }}
                d="m99 307.3 14.9-10.8H132l45.5-32V262"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "186.084,186.084",
                    strokeDashoffset: 0.426797,
                }}
                d="m256.8 261.2-1.7-2.7H201l-91.2 65H93"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "110.724,110.724",
                    strokeDashoffset: 0.253955,
                }}
                d="m127.5 286.1 53-36.6H216l3.5-4h5.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "222.251,222.251",
                    strokeDashoffset: 0.50975,
                }}
                d="m274.2 249.3-3.9-1.8H253l-11.7-8H63l-3.5 2.8v3.7"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "295.356,295.356",
                    strokeDashoffset: 0.677421,
                }}
                d="M1 245.5h39l16.9-12H285l5.8-4.8"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "305.909,305.909",
                    strokeDashoffset: 0.701625,
                }}
                d="m289.8 206.8-7.3 5.2v7l-8.3 6.5H53l-18 13H-1"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "285.493,285.493",
                    strokeDashoffset: 0.654801,
                }}
                d="m276.2 207.2-8.2 5.3h-13l-2.3-3H187l-10.8 10H47l-16.5 11H1"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "274.521,274.521",
                    strokeDashoffset: 0.629636,
                }}
                d="m259.2 186.5-6.7 3.5v7l-9.7 7.5H183l-11.6 7.5-129.2 1.2-15.5 11.3H1"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "215.58,215.58",
                    strokeDashoffset: 0.494449,
                }}
                d="M208 194.5h-26l-17.5 12H39l-16 10.9-23.5.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "217.408,217.408",
                    strokeDashoffset: 0.498643,
                }}
                d="m208.2 183-2.9 2.5H180l-20.2 14H34l-16.5 12H0"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "164.222,164.222",
                    strokeDashoffset: 0.376655,
                }}
                d="M159 186.5h-7l-9.4 6H29l-15.7 11H0"
            />
            <path
                className="st0"
                d="M93.5 179c3.2-.3 41.8 0 41.8 0l7.3 4"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "50.1264,50.1264",
                    strokeDashoffset: 0.114969,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "217.43,217.43",
                    strokeDashoffset: 0.498692,
                }}
                d="M0 189.5h10l10.3-6H46l21.7-16H206l3.8 2.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "281.383,281.383",
                    strokeDashoffset: 0.645374,
                }}
                d="M-1 167.5h12l13.7-10H230l12.2 10H270l2.7 2.3"
            />
            <path
                className="st0"
                d="M.6 137.6h260.8l14.9-9.6"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "278.525,278.525",
                    strokeDashoffset: 0.638818,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "203.061,203.061",
                    strokeDashoffset: 0.465736,
                }}
                d="M1 119.5h9l11.7 9H201"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "233.263,233.263",
                    strokeDashoffset: 0.535007,
                }}
                d="m-.1 145.8 4.1-3.3h228"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "280.564,280.564",
                    strokeDashoffset: 0.643494,
                }}
                d="m305 146.2-3.5-3.7H286l-3.7-2H275l-12.7 10H30"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "259.325,259.325",
                    strokeDashoffset: 0.594782,
                }}
                d="M0 52.5h36l31.7 21H253"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "88.6254,88.6254",
                    strokeDashoffset: 0.203269,
                }}
                d="M245 90.5h38l30 21 14 .4"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "128.982,128.982",
                    strokeDashoffset: 0.29583,
                }}
                d="M83 38.5h19l16.1-11H173l16.5-12.8V0"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "111.878,111.878",
                    strokeDashoffset: 0.2566,
                }}
                d="M211.5 0v21l-29 19.8V45l-6.3 5.5H133"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "87.4081,87.4081",
                    strokeDashoffset: 0.200477,
                }}
                d="M72 61.5h29l32-23h19"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "118.427,118.427",
                    strokeDashoffset: 0.271622,
                }}
                d="m116.1 62 9.2 6.5H223l7.7-5.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "61.0108,61.0108",
                    strokeDashoffset: 0.139933,
                }}
                d="M222.6.4 232 7l-1 41.5 7 3.9"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "241.963,241.963",
                    strokeDashoffset: 0.554962,
                }}
                d="M252.5 1v56l37.1 25.5H330l39.5 29V146l13.8 10"
            />
            <path
                className="st0"
                d="M317.9 97.7c1.3.8 16.6-.1 16.6-.1l21 15.4v22"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "64.6827,64.6827",
                    strokeDashoffset: 0.148355,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "59.0295,59.0295",
                    strokeDashoffset: 0.135389,
                }}
                d="M327 105.5h10l11.5 7.5v30l4.5 2.8"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "101.264,101.264",
                    strokeDashoffset: 0.232256,
                }}
                d="M110.6.2 99 8.5H12"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "185.004,185.004",
                    strokeDashoffset: 0.424322,
                }}
                d="M0 38.5h57l11.5-7H90l18.3-14H152l17.5-13V0"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "152.275,152.275",
                    strokeDashoffset: 0.349255,
                }}
                d="M8.5 28v-3L23 15.5h106L149.8 0"
            />
            <path
                className="st0"
                d="M272.5 1v59s-.1.5.2.9c.3.4.2.5.8.7.5.2.6.2 1.2.2.6.1.9.1 1.3-.1s.6-.4 1-.7.3-.1.5-1.1V0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "126.003,126.003",
                    strokeDashoffset: 0.288998,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "148.178,148.178",
                    strokeDashoffset: 0.339859,
                }}
                d="M311.5 0v65l4.2 5.5H340l31.3 22H385"
            />
            <path
                className="st0"
                d="M330.5 62V33s0-1.1.5-1.6.8-.8 1.2-1c.5-.2.5-.2 1.2 0s1.3.8 1.7 1.2c.3.5.3 1.3.3 1.3v29l32.8 23.5h6.7"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "113.025,113.025",
                    strokeDashoffset: 0.259231,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "135.661,135.661",
                    strokeDashoffset: 0.311149,
                }}
                d="M379.5 1v73l20 13.5V122l3.5 2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "163.668,163.668",
                    strokeDashoffset: 0.375385,
                }}
                d="m392.7-.1-4.2 2.3V70l20 15.5V114l9 7.5V143l3.2 2.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "114.778,114.778",
                    strokeDashoffset: 0.263252,
                }}
                d="m405.7.9-6.2 3.6V68l17.1 12.7 1.9 1.3v18l2.2 1.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "123.26,123.26",
                    strokeDashoffset: 0.282707,
                }}
                d="M415.5 0v2l-7 5v50l7 5.8V73l7 5.5V97l10 5.4v4.6"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "59.2032,59.2032",
                    strokeDashoffset: 0.135787,
                }}
                d="m420.5-.1.2 4.4-8.2 4.5V50l4.1 1.1"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "162.502,162.502",
                    strokeDashoffset: 0.37271,
                }}
                d="m448.2 146.2-8.7-6.1V129l9-7.5V100l7-5.1V69l-10-7.5V1"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "65,65",
                    strokeDashoffset: 0.149083,
                }}
                d="M430.5 0v65"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "69.8877,69.8877",
                    strokeDashoffset: 0.160293,
                }}
                d="M441.5 0v65l4.2 2.5"
            />
            <path
                className="st0"
                d="M460.3 105.6s5.2-3.1 5.2-4.3V65.1l-10-8V0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "112.96,112.96",
                    strokeDashoffset: 0.259082,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "158.632,158.632",
                    strokeDashoffset: 0.363835,
                }}
                d="m472.5 146.4 7-3.9V133l-5.5-3 .7-68.5-9.2-6.3V0"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "218.519,218.519",
                    strokeDashoffset: 0.50119,
                }}
                d="m439.3 160 6.4 4.5H460l15-10h11l6.5-4V133l-8-4.5V59l-9.5-7.9-.3-51.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "74.107,74.107",
                    strokeDashoffset: 0.16997,
                }}
                d="m503.8 133 6.7-5v-12l-18-12.5V74l1.2-2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "19.5391,19.5391",
                    strokeDashoffset: 0.0448145,
                }}
                d="M504.5 82v13l6 2.6"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "55.066,55.066",
                    strokeDashoffset: 0.126298,
                }}
                d="m528.8 106.8-4.3 2.9V153l-4.8 4.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "59.9441,59.9441",
                    strokeDashoffset: 0.137486,
                }}
                d="m533.2 146.6 7.3-6.1V98l-7.3-3.1"
            />
            <path
                className="st0"
                d="m551 154.9 24.5-17.3V100s41.2-26.3 41.8-28c.7-1.7 0-39.5 0-39.5l-3.7-2.5"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "161.921,161.921",
                    strokeDashoffset: 0.371379,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "178.35,178.35",
                    strokeDashoffset: 0.409059,
                }}
                d="M629.5 1v15l-5 4.8V73l-43 30.5V155"
            />
            <path
                className="st0"
                d="M593.4 142.2s-6.9-2.7-6.9-4.1V107l19-12.5H654l3 3.1"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "114.843,114.843",
                    strokeDashoffset: 0.263401,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "43.3328,43.3328",
                    strokeDashoffset: 0.0993872,
                }}
                d="M650.5 110v22L633 144.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "57.8638,57.8638",
                    strokeDashoffset: 0.132715,
                }}
                d="m605.3 111.1 8.2 6.5V165"
            />
            <path
                className="st0"
                d="m680.2 0-29.7 20.8V78s0 .5.6 1.5.8 1.2 1.8 1.6c.9.3 1.8.3 1.8.3l1.2-.2s.5-.2.8-.8c.2-.6-.2-1.3-.2-1.3V29s-.2-.3.7-1.2c.8-.8.9-1 1.5-1.2.6-.2.6-.2 1.2-.3.7-.1 1-.1 1.6.3.6.4 1 .2 1.5.9s.5 1.5.5 1.5v52l13 9.5V107"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "247.51,247.51",
                    strokeDashoffset: 0.567684,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "150.734,150.734",
                    strokeDashoffset: 0.34572,
                }}
                d="M747.2.4 687.5 42v28l17 12.2V104l-6.5 3.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "186.585,186.585",
                    strokeDashoffset: 0.427948,
                }}
                d="m700.2 144.4 7.3-5.8V120l10-8.1V45l64.8-45"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "294.669,294.669",
                    strokeDashoffset: 0.675847,
                }}
                d="m817.5 0-84 59v113l-20.8 15.3-12.4-.3-14.3 9.7-15.7-.4-6 5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "77.9008,77.9008",
                    strokeDashoffset: 0.178672,
                }}
                d="m804.7 102.7-9.4 6.3-.6 59.7 5.6 4"
            />
            <path
                className="st0"
                d="M2 57.2c7.4-.1 14.7 0 22.1-.1L57 80.5h135s.7-.2.7.8.3 1.2-1.2 1.3c-1.5.2-90.7-.2-90.7-.2s-1.4-.2-2.1.5-.9 1.2-.9 1.8c0 .7.2 1.2.6 1.4.3.2.2.3.8.8s1.6.4 1.8.5h113.7l12.8 7.5v22l-4.7 2.6"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "456.511,456.511",
                    strokeDashoffset: 1.04704,
                }}
            />
            <path
                className="st0"
                d="m215.8 95.1-2.6-2-30.1-.2-5.2 3.6h-71.2c-.1 0-1 .1-1.6-.8-.5-.7-.2-1.4-.2-1.4.2-.8 1.1-1.5 2-1.6l65 .2c.2.1.9.2 1.7-.2.2-.1.7-.4.8-.9 0 0 .2-.6-.2-1.2-.3-.5-.8-.6-1.4-.8-.5-.1-1.4-.2-2.5 0l-72.2.2-9.8-6.6-35.2-.2-13.7-9.3L0 74"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "365.562,365.562",
                    strokeDashoffset: 0.838445,
                }}
            />
            <path
                className="st0"
                d="M190 105.3 173.1 118l-74.2-.1c-.9 0-1.6-.2-2-.3-.5-.2-1.1-.5-1.4-1.1-.1-.1-.2-.6-.1-1.1.3-.9 1.3-1.2 1.3-1.2l62.2.2c.3 0 .8.1 1.3 0 .9-.1 1.5-.1 2-.6.6-.5.7-1.2.8-1.3 0-.1.1-.7-.2-1.2-.4-1-1.4-1.3-1.6-1.3l-128-.1-7.4.1-14.4-10.5-11.4.2"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "337.352,337.352",
                    strokeDashoffset: 0.773742,
                }}
            />
            <path
                className="st0"
                d="m287 0-.1 58.4s-.2.1.7.7c.8.6.4.6 1.3.7.9.1.8.2 1.4-.1.7-.2 1.3-.7 1.6-.9S292 0 292 0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "123.065,123.065",
                    strokeDashoffset: 0.282259,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "226.396,226.396",
                    strokeDashoffset: 0.519256,
                }}
                d="m296.6 0-.1 1v68l3.5 3.5h37l46.3 33 1.9 18.5 9.3 8.5V153l6.2 3"
            />
            <path
                className="st0"
                d="m301.2 0-.2 65.8s.2.6 1 1.1.5.6 1.4.6c.9 0 1.1 0 1.7-.4.6-.4.9-.5 1.2-1s-.1-66-.1-66"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "138.261,138.261",
                    strokeDashoffset: 0.317112,
                }}
            />
            <path
                className="st0"
                d="M326.4 61.4c.1.2.2.7.5 1.1.2.2.4.6.9.8.7.2 1.3-.1 1.5-.2.5-.2.7-.6.8-.8"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "5.1442,5.1442",
                    strokeDashoffset: 0.0117986,
                }}
            />
            <path
                className="st0"
                d="m326.8 62-.3-29.1s-.2-.5-.8-.9c-.5-.4-1.1-.8-1.8-.8s-.7-.1-1.4.6c-.8.7-.9 1-1.2 1.6-.2.6-.2 2.8-.2 2.8l.3 25.9s0 .6-.6 1.1c-.6.5-2.2 1-2.2 1s-1.1-.1-1.8-.6-1.2-.8-1.2-1.9c-.1-1.2-.2-44.2-.2-44.2l3.1-2.4V0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "135.903,135.903",
                    strokeDashoffset: 0.311705,
                }}
            />
            <path
                className="st0"
                d="M347.6 0v11.1l17.1 11.3.4 40.7c0 .1-.2 1.3.5 2.1.6.6 1.4.7 2 .7.6 0 1.5 0 2.1-.6.7-.6.8-1.8.4-2.8l-.5-43.3c0-.2-.1-1.2.6-1.9.5-.5 1.1-.6 1.6-.7.5-.1 1.1-.2 1.7.1.9.4 1 1.4 1.1 1.7.2.8-.1 1.4-.1 1.7V76"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "190.551,190.551",
                    strokeDashoffset: 0.437045,
                }}
            />
            <path
                className="st0"
                d="M519.9 0s-.8.7-1 1.8c-.2 1.1-.2 1.6-.2 2.4s.2 1.3.7 1.8c.4.4.8.8 1.2 1 .4.2 1.3.6 1.3.6h92s-.1-.1 1.2.5 1.8 1.2 2.2 2.1c.4.9.8 2.1.7 3.4-.1 1.3-.2 2-.8 2.8-.7.8-1.2 1.5-2 1.8-.8.3-1.6.8-1.6.8s-113-1.3-114 0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "230.947,230.947",
                    strokeDashoffset: 0.529694,
                }}
            />
            <path
                className="st0"
                d="m500.1 18.8-3.1.2c-1 .4-1.8 1.1-2.4 1.8-.7.7-.7 1.5-.6 2.8.1 1.3.2 1.7 1.3 2.8 1.2 1.2 1.9 1.3 3.1 1.8 1.2.4 2.6.2 2.6.2h91"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "109.55,109.55",
                    strokeDashoffset: 0.251262,
                }}
            />
            <path
                className="st0"
                d="M519.9 54.6c.3-1 .9-2.8 2.5-4.2.3-.3 1.3-1.2 3.8-2.1 3-1.1 5.6-1.2 6-1.2 9-.4 33.7-.3 33.7-.2.4 0 .9.1 1.6.2 2.3.6 3.7 1.9 4.2 2.4.9.8 2.4 2.3 2.8 4.8.1.3.4 2.8-.9 5.1-.3.6-.8 1.1-1.8 2.2-.6.6-1.1 1.2-3 2.8-1.9 1.7-2.5 2.2-3.9 3.5-.9.9-1.7 1.6-2.2 2l-1.2 2.2v32"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "116.316,116.316",
                    strokeDashoffset: 0.266779,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "161.412,161.412",
                    strokeDashoffset: 0.37021,
                }}
                d="m824.5 94.8.2-5.5L952.5 0"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "153.106,153.106",
                    strokeDashoffset: 0.351161,
                }}
                d="M960 1.5 834.5 89.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "49,49",
                    strokeDashoffset: 0.112385,
                }}
                d="M800.5 113v49"
            />
            <path
                className="st0"
                d="M685.8 156.8c1.2 0 8.7.3 8.7.3s.3-.2 1.2.3c.8.5 1.2.3 1.3 2s.2 9 .2 9c.2.2.5.7 1.2 1 .1.1.9.4 1.8.3 1.1-.1 1.7-.7 2.2-1 .6-.5 1-1 1.2-1.3l-.3-11.2.8-1.2c.2-.3.8-.8 1.7-1 1-.2 1.9.4 2.4.7.7.4 1.4 1 1.4 1.3v7.8c0 .8.5 1.2.8 1.3 0 0 .2.2 1.6.3.1 0 1 .1 1.6-.7.6-.7.3-1.6.3-1.7l-.7-23.2c0-.1.1-1.6 1.2-2.2.6-.3 1.2-.2 1.7-.2.4.1 1.3.2 2 1s.8 1.7.8 2l-.3 24.5c0 .1-.3 1 .2 1.7.4.5 1.1.5 1.7.5 0 0 .6 0 2.3-.5.3-.6.6-1.1.8-1.7l-.2-119.2 9.2-6.5 14.8-.2 56.3-39"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "341.226,341.226",
                    strokeDashoffset: 0.782628,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "260.668,260.668",
                    strokeDashoffset: 0.597862,
                }}
                d="m637.4.2-.5 19.6-5.2 2.9.1 64.2 6.2 4.6 22.8-.2 5.9 4.5.1 5.5-4 3.4.5 31-9.8 6.8V160l30.3 20.5H709"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "110.337,110.337",
                    strokeDashoffset: 0.253067,
                }}
                d="M893 185.5h-5l-3.2 2.5-35-.2-54.3 38"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "225.9,225.9",
                    strokeDashoffset: 0.51812,
                }}
                d="m803.7 493.3-13-.1-8.7 6.1-.3 8.2 22.5 16.2 43.6-.9 48 33.5 64.2-.1"
            />
            <path
                className="st0"
                d="m791.7 526.5 7.5 5.3 38 .2s16.3 10.7 16.3 11.3c0 .7.3 16.7.3 16.7"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "83.7422,83.7422",
                    strokeDashoffset: 0.192069,
                }}
            />
            <path
                className="st0"
                d="m-.2 390.8 12.1.4L28.6 379l28.9-.2c.1 0 1 0 1.5.7.6.7.6 1.6.2 2.3-.4.7-1.3 1.1-2.2 1l-23.3-.2-1.3.7c-.5.4-.7 1.1-.5 1.7.3.7 1.3 1.1 2.2.7l25-.2c.1 0 .9.1 1.3.8 0 0 .6.9.2 1.8-.3.7-1.2 1.1-2 1l-24.9-.1c-.1 0-1.2.1-1.7 1.2 0 .1-.4.9 0 1.7.4.8 1.4 1.2 2.5 1l42.3.2 118.3-83.7 51.8.1 4.2-2.9H272l2.7-1.3"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "428.228,428.228",
                    strokeDashoffset: 0.982174,
                }}
            />
            <path
                className="st0"
                d="M275.6 329.7c-.7.3-12.2 8.8-12.2 8.8s-10.4-.7-10.9.1c-.5.8-16.7 12.8-16.7 12.8h-26.2l-17.5 13.2H188"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "99.2827,99.2827",
                    strokeDashoffset: 0.227713,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "107.06,107.06",
                    strokeDashoffset: 0.24555,
                }}
                d="m274.7 349.5-5-4H257l-17.8 12.7-24.5.3-13.2 9.5v8l-8 5.7-7.5-.2"
            />
            <path
                className="st0"
                d="m184.3 390.3-2.7 2-.2 13.7c-.1.2-.4 1.1 0 2 .6 1.1 1.9 1.3 2.3 1.3.2 0 1.6.2 2.7-.8 1-.9 1.1-2.1 1-2.3-.5-1 0-10 0-10 0-.3.2-1 .8-1.7.2-.2.8-.8 1.8-.8.9 0 2 .5 2.3 1.5.3.7.1 1.4 0 1.7l.2 3.7.3 2.7c.1.1.8.8 1.8.8s1.6-.6 1.8-.8c.9-.9 1.2-2.1 1-2.3-.3-.3-.5-14.2-.5-14.2 0-.1-.2-1.3.7-2 .8-.8 2-.4 2.3-.3.2.1 1.3.4 1.8 1.5.5 1 .1 2 0 2.2l-.2 8.3c.1.2.4 1.1 1.3 1.3.7.2 1.2-.1 1.8-.3.5-.2 1.1-.5 1.5-1.2.6-.9.4-1.9.3-2.2-.2-.5 0-25.3 0-25.3l10.3-7.5 28-.2 13.5-9.5"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "181.554,181.554",
                    strokeDashoffset: 0.416409,
                }}
            />
            <path
                className="st0"
                d="M0 470.5h12l54.3-38.7 54.3.3 35.7-24.7c.2-.1 1.1-.7 1.3-1.8.2-.9-.1-1.9-.8-2.5-.9-.7-2-.5-2.7-.3-1.1.2-1.8.8-2.2 1.2l-29.5 20.7c-.3.4-.8 1-1.7 1.5-.6.3-1.7.9-2.5.3-.6-.4-1-1.3-.7-2.2.2-.6.8-1.1 1.5-1.2l46.7-32.8c.4-.3 1-.8 1.7-1.3 1.8-1.4 2.7-1.7 2.7-2.3 0-.7-.9-1.2-1.3-1.5-1.2-.7-2.4-.5-2.7-.5-1.3.2-2.1 1.1-2.3 1.3l-22.7 16c-.2.4-1 1.4-2.3 1.8-.2.1-2.2.7-3.2-.5-.6-.8-.4-1.9-.3-2 .2-.9 1-1.6 2-1.8L170 377l.2-22.2 28.7-20 20.2.3 9.3-6.3"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "470.111,470.111",
                    strokeDashoffset: 1.07824,
                }}
            />
            <path
                className="st0"
                d="m0 443.3 35.2.2 25.7-18.2 24.7-.2 27.8-19.3c1.2-.9 1.8-1.9 1.5-2.3-.3-.5-1.5-.2-1.7-.2l-2 .7-19.5 14c-.3.3-1.5 1.3-3.2 1.2-.3 0-2.4-.2-2.7-1.5-.2-.7.3-1.6 1.2-2.2"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "165.543,165.543",
                    strokeDashoffset: 0.379685,
                }}
            />
            <path
                className="st0"
                d="m86.5 415.5 69.5-47.8c1.1-.4 1.9-1.3 1.8-2.2 0-.7-.6-1.3-1-1.7-.4-.4-1-.9-1.8-1-1.4-.1-2.4 1.2-2.5 1.3l-34.2 24c-.3.3-1 1.2-2.2 1.5-.3.1-1.9.5-3.2-.5-.2-.1-1.1-.9-1-2.2.1-.9.7-1.6 1.1-1.9.2-.2.4-.3.4-.3l81.2-56.3 9.3-.2 1.8-1.7"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "258.163,258.163",
                    strokeDashoffset: 0.592116,
                }}
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "156.94,156.94",
                    strokeDashoffset: 0.359954,
                }}
                d="m960 335-63.3.3-40.5 28.7-44 .3"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "232.802,232.802",
                    strokeDashoffset: 0.53395,
                }}
                d="m755.8 371-4.3-2.7V363l7-3.5H787l14.5 10H857l44.7-31.5 58.3-.3"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "281.272,281.272",
                    strokeDashoffset: 0.64512,
                }}
                d="m686 323.7 3.9-2.7 27.1.4 13.8-9.9H934l13.8 10H960"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "321.901,321.901",
                    strokeDashoffset: 0.738305,
                }}
                d="m666.7 334 10.9-7.7.1-7.7-5.3-2.9.3-4.3 4.3-2.9h261l3.5-3H952l4.5 3.5 3.5-.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "313.632,313.632",
                    strokeDashoffset: 0.71934,
                }}
                d="m653.8 288.7 3.9-3.2H680l20.3 14 249.7.7 10 6.5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "259.418,259.418",
                    strokeDashoffset: 0.594995,
                }}
                d="m705.2 276.6 8.1 5.6 223.4.3 7.1-7H960"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "164.336,164.336",
                    strokeDashoffset: 0.376918,
                }}
                d="M798.7 277.9h132.8l7.5-7.4h21"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "364.499,364.499",
                    strokeDashoffset: 0.836007,
                }}
                d="m322.1 149.1-6.6 4.7.5 17.5-6.1 5.1-4-.3-3.5 3.2-.3 6.6-5 3.9-7.1.6-22.9-16.4-31.9-.2-17.8-12.2-183.6-.1H29L15.8 171l-15.8.2"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "93.0013,93.0013",
                    strokeDashoffset: 0.213306,
                }}
                d="m960 235-93 .5"
            />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "111.402,111.402",
                    strokeDashoffset: 0.255509,
                }}
                d="M960 259.5h-83l-9 7h-17"
            />
            <circle className="st1" cx={263} cy={186} r={2.8} />
            <circle className="st1" cx={275} cy={172} r={2.8} />
            <circle className="st1" cx={235} cy={142} r={2.8} />
            <circle className="st1" cx={279} cy={126.5} r={2.8} />
            <circle className="st1" cx={306.7} cy={148.5} r={2.8} />
            <circle className="st1" cx={325.5} cy={148} r={2.8} />
            <circle className="st1" cx={212} cy={171.5} r={2.8} />
            <circle className="st1" cx={91.5} cy={179} r={2.8} />
            <circle className="st1" cx={145.3} cy={185} r={2.8} />
            <circle className="st1" cx={162} cy={186.5} r={2.8} />
            <circle className="st1" cx={210.5} cy={181} r={2.8} />
            <circle className="st1" cx={211} cy={195.5} r={2.8} />
            <circle className="st1" cx={122.5} cy={245.5} r={2.8} />
            <circle className="st1" cx={69} cy={247} r={2.8} />
            <circle className="st1" cx={152} cy={328.5} r={2.8} />
            <circle className="st1" cx={200} cy={292} r={2.8} />
            <circle className="st1" cx={175.5} cy={297} r={2.8} />
            <circle className="st1" cx={258} cy={264} r={2.8} />
            <circle className="st1" cx={218} cy={266.5} r={2.8} />
            <circle className="st1" cx={293.7} cy={260.5} r={2.8} />
            <circle className="st1" cx={277} cy={251} r={2.8} />
            <circle className="st1" cx={228.5} cy={245.5} r={2.8} />
            <circle className="st1" cx={294} cy={239.5} r={2.8} />
            <circle className="st1" cx={281} cy={192.5} r={2.8} />
            <circle className="st1" cx={293.5} cy={227} r={2.8} />
            <circle className="st1" cx={292.5} cy={205.5} r={2.8} />
            <circle className="st1" cx={279} cy={206} r={2.8} />
            <circle className="st1" cx={277.3} cy={304.5} r={2.8} />
            <circle className="st1" cx={90.3} cy={322.5} r={2.8} />
            <circle className="st1" cx={94} cy={300.5} r={2.8} />
            <circle className="st1" cx={97} cy={309} r={2.8} />
            <circle className="st1" cx={177.5} cy={259.5} r={2.8} />
            <circle className="st1" cx={125.5} cy={288} r={2.8} />
            <circle className="st1" cx={83.5} cy={278} r={2.8} />
            <circle className="st1" cx={97.5} cy={282.5} r={2.8} />
            <circle className="st1" cx={135} cy={246.5} r={2.8} />
            <circle className="st1" cx={163.2} cy={470.2} r={2.8} />
            <circle className="st1" cx={101} cy={513} r={2.8} />
            <circle className="st1" cx={138.5} cy={477.3} r={2.8} />
            <circle className="st1" cx={81.5} cy={525} r={2.8} />
            <circle
                transform="rotate(-25.177 105.696 486.99)"
                className="st1"
                cx={105.7}
                cy={487}
                r={2.8}
            />
            <circle className="st1" cx={82.5} cy={498} r={2.8} />
            <circle className="st1" cx={135} cy={470} r={2.8} />
            <circle className="st1" cx={82} cy={491.8} r={2.8} />
            <circle className="st1" cx={108} cy={471.5} r={2.8} />
            <circle className="st1" cx={258.2} cy={418.5} r={2.8} />
            <circle className="st1" cx={347} cy={399.3} r={2.8} />
            <circle className="st1" cx={276.7} cy={385} r={2.8} />
            <circle className="st1" cx={262} cy={384} r={2.8} />
            <circle className="st1" cx={219.5} cy={375} r={2.8} />
            <circle className="st1" cx={187.2} cy={389.2} r={2.8} />
            <circle className="st1" cx={261} cy={350} r={2.8} />
            <circle className="st1" cx={276.5} cy={351} r={2.8} />
            <circle className="st1" cx={183.5} cy={382} r={2.8} />
            <circle className="st1" cx={187} cy={374} r={2.8} />
            <circle className="st1" cx={186} cy={364} r={2.8} />
            <circle className="st1" cx={193} cy={355.5} r={2.8} />
            <circle className="st1" cx={227.8} cy={345.5} r={2.8} />
            <circle className="st1" cx={208} cy={324.5} r={2.8} />
            <circle className="st1" cx={230.5} cy={327.5} r={2.8} />
            <circle className="st1" cx={278.5} cy={328.3} r={2.8} />
            <circle className="st1" cx={375} cy={410.3} r={2.8} />
            <circle className="st1" cx={327} cy={538} r={2.8} />
            <circle className="st1" cx={338.2} cy={525.5} r={2.8} />
            <circle className="st1" cx={305.7} cy={553.5} r={2.8} />
            <circle className="st1" cx={286.8} cy={553} r={2.8} />
            <circle className="st1" cx={360.5} cy={421.3} r={2.8} />
            <circle className="st1" cx={356} cy={407} r={2.8} />
            <circle className="st1" cx={323.5} cy={432.3} r={2.8} />
            <circle className="st1" cx={325.8} cy={408.2} r={2.8} />
            <circle className="st1" cx={208} cy={491} r={2.8} />
            <circle className="st1" cx={262.8} cy={452.2} r={2.8} />
            <circle className="st1" cx={98} cy={523} r={2.8} />
            <circle className="st1" cx={525.8} cy={452} r={2.8} />
            <circle className="st1" cx={514.7} cy={420.5} r={2.8} />
            <circle className="st1" cx={492} cy={382.7} r={2.8} />
            <circle className="st1" cx={536} cy={533} r={2.8} />
            <circle className="st1" cx={485.5} cy={419} r={2.8} />
            <circle className="st1" cx={487.5} cy={407.8} r={2.8} />
            <circle className="st1" cx={459} cy={554} r={2.8} />
            <circle className="st1" cx={465.5} cy={435.5} r={2.8} />
            <circle className="st1" cx={414} cy={544.3} r={2.8} />
            <circle className="st1" cx={440.2} cy={397.2} r={2.8} />
            <circle className="st1" cx={437} cy={418.8} r={2.8} />
            <circle className="st1" cx={422} cy={423} r={2.8} />
            <circle className="st1" cx={408.5} cy={410} r={2.8} />
            <circle className="st1" cx={407.5} cy={398} r={2.8} />
            <circle className="st1" cx={393.5} cy={398.5} r={2.8} />
            <circle className="st1" cx={831} cy={513} r={2.8} />
            <circle className="st1" cx={793} cy={501.5} r={2.8} />
            <circle className="st1" cx={790.8} cy={524.2} r={2.8} />
            <circle className="st1" cx={749} cy={493.2} r={2.8} />
            <circle className="st1" cx={608.5} cy={456.5} r={2.8} />
            <circle className="st1" cx={567.5} cy={428.5} r={2.8} />
            <circle className="st1" cx={535.5} cy={408.5} r={2.8} />
            <circle className="st1" cx={667.5} cy={386} r={2.8} />
            <circle className="st1" cx={679.5} cy={397} r={2.8} />
            <circle className="st1" cx={713.8} cy={388.5} r={2.8} />
            <circle className="st1" cx={724.3} cy={404} r={2.8} />
            <circle className="st1" cx={699.7} cy={408.5} r={2.8} />
            <circle className="st1" cx={665.5} cy={407.3} r={2.8} />
            <circle className="st1" cx={762} cy={420} r={2.8} />
            <circle className="st1" cx={859.5} cy={444.5} r={2.8} />
            <circle className="st1" cx={853.8} cy={435.5} r={2.8} />
            <circle className="st1" cx={873.3} cy={443} r={2.8} />
            <circle className="st1" cx={800} cy={472} r={2.8} />
            <circle className="st1" cx={665.3} cy={430.5} r={2.8} />
            <circle className="st1" cx={649.8} cy={432.5} r={2.8} />
            <circle className="st1" cx={806.3} cy={493.5} r={2.8} />
            <circle className="st1" cx={633.5} cy={420.5} r={2.8} />
            <circle className="st1" cx={765.5} cy={346} r={2.8} />
            <circle className="st1" cx={786.5} cy={343.5} r={2.8} />
            <circle className="st1" cx={871} cy={343.5} r={2.8} />
            <circle className="st1" cx={809.5} cy={364.5} r={2.8} />
            <circle className="st1" cx={848.8} cy={356} r={2.8} />
            <circle className="st1" cx={883.5} cy={371.5} r={2.8} />
            <circle className="st1" cx={758.8} cy={373} r={2.8} />
            <circle className="st1" cx={661.5} cy={377} r={2.8} />
            <circle className="st1" cx={802} cy={268} r={2.8} />
            <circle className="st1" cx={796.3} cy={277.5} r={2.8} />
            <circle className="st1" cx={703.5} cy={275} r={2.8} />
            <circle className="st1" cx={653.5} cy={291.5} r={2.8} />
            <circle className="st1" cx={665} cy={336} r={2.8} />
            <circle className="st1" cx={684} cy={325.5} r={2.8} />
            <circle className="st1" cx={729.5} cy={334} r={2.8} />
            <circle className="st1" cx={770} cy={332} r={2.8} />
            <circle className="st1" cx={878.7} cy={331.5} r={2.8} />
            <circle className="st1" cx={438} cy={158} r={2.8} />
            <circle className="st1" cx={406} cy={125} r={2.8} />
            <circle className="st1" cx={403.5} cy={157} r={2.8} />
            <circle className="st1" cx={386} cy={158} r={2.8} />
            <circle className="st1" cx={356} cy={138} r={2.8} />
            <circle className="st1" cx={355.5} cy={147.5} r={2.8} />
            <circle className="st1" cx={115} cy={60.2} r={2.8} />
            <circle className="st1" cx={233} cy={61.5} r={2.8} />
            <circle className="st1" cx={330} cy={112} r={2.8} />
            <circle className="st1" cx={242.5} cy={90.5} r={2.8} />
            <circle className="st1" cx={256} cy={74} r={2.8} />
            <circle className="st1" cx={217.5} cy={97} r={2.8} />
            <circle className="st1" cx={192.5} cy={104} r={2.8} />
            <circle className="st1" cx={204} cy={128.5} r={2.8} />
            <circle className="st1" cx={220.5} cy={120.5} r={2.8} />
            <circle className="st1" cx={423} cy={147.5} r={2.8} />
            <circle className="st1" cx={375} cy={79} r={2.8} />
            <circle className="st1" cx={367} cy={70} r={2.8} />
            <circle className="st1" cx={378} cy={85} r={2.8} />
            <circle className="st1" cx={388} cy={93} r={2.8} />
            <circle className="st1" cx={315.5} cy={98} r={2.8} />
            <circle className="st1" cx={324.5} cy={106} r={2.8} />
            <circle className="st1" cx={80.5} cy={39} r={2.8} />
            <circle className="st1" cx={69.5} cy={61.5} r={2.8} />
            <circle className="st1" cx={240.5} cy={54} r={2.8} />
            <circle className="st1" cx={155} cy={39} r={2.8} />
            <circle className="st1" cx={130.5} cy={50.5} r={2.8} />
            <circle className="st1" cx={531} cy={94.5} r={2.8} />
            <circle className="st1" cx={531.5} cy={106.5} r={2.8} />
            <circle className="st1" cx={517.5} cy={159.5} r={2.8} />
            <circle className="st1" cx={505.5} cy={80} r={2.8} />
            <circle className="st1" cx={513} cy={99} r={2.8} />
            <circle className="st1" cx={501.8} cy={134.5} r={2.8} />
            <circle className="st1" cx={496} cy={70.5} r={2.8} />
            <circle className="st1" cx={470.5} cy={148} r={2.8} />
            <circle className="st1" cx={448} cy={69.5} r={2.8} />
            <circle className="st1" cx={430.5} cy={68} r={2.8} />
            <circle className="st1" cx={450.5} cy={148} r={2.8} />
            <circle className="st1" cx={433} cy={110} r={2.8} />
            <circle className="st1" cx={423.5} cy={103} r={2.8} />
            <circle className="st1" cx={420} cy={52} r={2.8} />
            <circle className="st1" cx={675.5} cy={215} r={2.8} />
            <circle className="st1" cx={662.3} cy={203} r={2.8} />
            <circle className="st1" cx={695.5} cy={108.5} r={2.8} />
            <circle className="st1" cx={692.5} cy={98} r={2.8} />
            <circle className="st1" cx={676.5} cy={110} r={2.8} />
            <circle className="st1" cx={683.5} cy={157.5} r={2.8} />
            <circle className="st1" cx={698.5} cy={146.5} r={2.8} />
            <circle className="st1" cx={712} cy={180.5} r={2.8} />
            <circle className="st1" cx={458.5} cy={107.5} r={2.8} />
            <circle className="st1" cx={611.5} cy={28.5} r={2.8} />
            <circle className="st1" cx={595} cy={28.5} r={2.8} />
            <circle className="st1" cx={520.5} cy={57.5} r={2.8} />
            <circle className="st1" cx={561.5} cy={107} r={2.8} />
            <circle className="st1" cx={650.5} cy={108} r={2.8} />
            <circle className="st1" cx={659} cy={100} r={2.8} />
            <circle className="st1" cx={631} cy={146.5} r={2.8} />
            <circle className="st1" cx={596} cy={143.5} r={2.8} />
            <circle className="st1" cx={582} cy={157.5} r={2.8} />
            <circle className="st1" cx={549} cy={156.5} r={2.8} />
            <circle className="st1" cx={604} cy={109.5} r={2.8} />
            <circle className="st1" cx={613.8} cy={167.5} r={2.8} />
            <circle className="st1" cx={531.5} cy={148.5} r={2.8} />
            <circle className="st1" cx={832.5} cy={91} r={2.8} />
            <circle className="st1" cx={824.5} cy={97.5} r={2.8} />
            <circle className="st1" cx={800.5} cy={111} r={2.8} />
            <circle className="st1" cx={801} cy={165} r={2.8} />
            <circle className="st1" cx={807.5} cy={102} r={2.8} />
            <circle className="st1" cx={802} cy={96} r={2.8} />
            <circle className="st1" cx={777} cy={78} r={2.8} />
            <circle className="st1" cx={762.5} cy={84.5} r={2.8} />
            <circle className="st1" cx={803} cy={174.5} r={2.8} />
            <circle className="st1" cx={696} cy={225.7} r={2.8} />
            <circle className="st1" cx={777.5} cy={198.5} r={2.8} />
            <circle className="st1" cx={791.5} cy={241.5} r={2.8} />
            <circle className="st1" cx={764.5} cy={250} r={2.8} />
            <circle className="st1" cx={795} cy={229} r={2.8} />
            <circle className="st1" cx={844.5} cy={198} r={2.8} />
            <circle className="st1" cx={675} cy={261.5} r={2.8} />
            <circle className="st1" cx={855} cy={152.5} r={2.8} />
            <circle className="st1" cx={836.5} cy={149} r={2.8} />
            <circle className="st1" cx={800.5} cy={184} r={2.8} />
            <circle className="st1" cx={824} cy={110} r={2.8} />
            <circle className="st1" cx={849} cy={265} r={2.8} />
            <circle className="st1" cx={834} cy={255.5} r={2.8} />
            <circle className="st1" cx={872.5} cy={249.3} r={2.8} />
            <circle className="st1" cx={868} cy={242.5} r={2.8} />
            <circle className="st1" cx={864.5} cy={235.5} r={2.8} />
            <circle className="st1" cx={871.8} cy={230.5} r={2.8} />
            <circle className="st1" cx={852} cy={254} r={2.8} />
            <circle className="st1" cx={813.5} cy={263.5} r={2.8} />
            <circle className="st1" cx={753.8} cy={257.5} r={2.8} />
            <path
                className="st0"
                style={{
                    transition: "none 0s ease 0s",
                    strokeDasharray: "89.2014,89.2014",
                    strokeDashoffset: 0.20459,
                }}
                d="M875 230.5h62l9.8-10H960"
            />
        </svg>
    );
};

export default Electric;
