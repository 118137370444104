import React, { useContext } from "react";
import SubjectTeacherContext from "../../context/SubjectTeacherContext";
import Container from "../../components/ui/Container";
import Categorie from "../../sections/home/CoursesSection/Categorie";
import SubjectTeacherInfoSection from "../../sections/course/SubjectTeacherInfoSection";
import SubscriptionInstructionText from "./SubscriptionInstructionText";
import auth from "../../services/authServices";
import Button from "../../components/ui/Button";
import CourseItemsCounter from "../../components/items/CourseItemsCounter";
import modal from "../../services/modalServices";
import { useLocation, useNavigate } from "react-router-dom";

const SubjectTeacherHome = () => {
    const { subjectTeacher } = useContext(SubjectTeacherContext);

    const token = auth.getToken();

    const navigate = useNavigate();
    let location = useLocation();
    return (
        <Container className="relative py-0">
            <div className="flex flex-col md:flex-row-reverse space-y-10 md:space-y-0 md:space-x-10">
                <div className=" md:basis-1/3 relative -mt-52">
                    <SubjectTeacherInfoSection />
                </div>
                <div className=" md:basis-2/3 font-h2 font-w-bold space-y-5">
                    <SubscriptionInstructionText
                        isSubjectTeacher={true}
                        subjectTeacher={subjectTeacher}
                        subscriptions_count={subjectTeacher.subscriptions_count}
                    />
                    {token ? (
                        subjectTeacher.subscriptions_count > 0 ? (
                            <Button color="cyan" className="w-full inline-block text-center">
                                انت مشترك بهذه المادة !
                            </Button>
                        ) : subjectTeacher.sellable ? (
                            <>
                                <Button
                                    element="Link"
                                    to={`subscribe/previous_invoices/`}
                                    color="blue"
                                    className="w-full inline-block text-center"
                                >
                                    اشترك الآن !
                                </Button>
                            </>
                        ) : (
                            <Button color="yellow" className="w-full inline-block text-center">
                                هذه المادة غير قابلة للإشتراك الآن
                            </Button>
                        )
                    ) : (
                        <>
                            <Button
                                onClick={() => {
                                    modal.message({
                                        title: `يجب عليك تسجيل الدخول اولًا`,
                                        text: "يجب عليك تسجيل الدخول لشراء الكورس",
                                        icon: "warning",
                                        button: "تسجيل الدخول",
                                        callback: (accept) => {
                                            if (accept) {
                                                navigate("/login", {
                                                    state: {
                                                        prevPath: location.pathname,
                                                    },
                                                });
                                            }
                                        },
                                    });
                                }}
                                color="blue"
                                className="w-full inline-block text-center"
                            >
                                اشترك الآن !
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <div className="w-full">
                {/* <TeachersGrid subjectId={subject.id} isSubject={false} /> */}
                {/* <SubjectsGrid teacherId={teacher.id} isTeacher={true} /> */}
                <Categorie
                    titleFirst="كورسات"
                    titleLast="ضمن الاشتراك الشهري"
                    coursesList={subjectTeacher.courses}
                />
            </div>
        </Container>
    );
};

export default SubjectTeacherHome;
