import React from "react";
import { getYearPlaceHolder } from "../../services/yearSevices";

const SubscriptionInstructionText = ({
    isSubject = false,
    isSubjectTeacher = false,
    subjectTeacher = null,
    subscriptions_count = 0,
}) => {
    return (
        <>
            {isSubjectTeacher ? (
                subjectTeacher && subscriptions_count > 0 ? (
                    <>
                        <div className="">
                            انت دلوقتي مشترك مع {subjectTeacher.teacher.name} في مادة{" "}
                            {subjectTeacher.subject.name} لـ{" "}
                            {getYearPlaceHolder(subjectTeacher.subject.year)}
                        </div>
                        <div>
                            دلوقتي متاحلك كل الكورسات اللي تحت دي طول الشهر تتفرج عليها في أي وقت
                        </div>
                    </>
                ) : (
                    <>
                        <div className="">
                            عند الاشتراك مع {subjectTeacher.teacher.name} في مادة{" "}
                            {subjectTeacher.subject.name} لـ{" "}
                            {getYearPlaceHolder(subjectTeacher.subject.year)}
                        </div>
                        <div>
                            بيكون متاح ليك كل الكورسات اللي تحت دي طول الشهر تتفرج عليها في أي وقت
                        </div>
                    </>
                )
            ) : (
                <>
                    <div className="font-w-bold">
                        يمكنك الاشتراك {isSubject ? " في هذه المادة" : "مع المدرس"} عن طريق :
                    </div>
                    <div>
                        ١- اشترك شهريًا مع المدرس {isSubject ? "اللي بتحبه و بترتاحله" : ""} و هتقدر
                        تتفرج على كل الكورسات المتاحة للمدرس خلال الشهر
                    </div>
                    <div>
                        ٢- اشترك بس في الكورس اللي انت عاوزه للمدرس{" "}
                        {isSubject ? "اللي انت عاوزه" : ""} و هيكون متاح ليك الكورس تتفرج عليه في أي
                        وقت خلال السنة
                    </div>
                </>
            )}
        </>
    );
};

export default SubscriptionInstructionText;
