import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/ui/Container";
import ScrollingContext from "../../context/ScrollingContext";
import {
    getCurrentScrollingPosition,
    getScrollingPercentage,
} from "../../services/scrollingServices";

import "./Wise1Section.css";

const Wise1Section = () => {
    const [toUsePercentage, setToUsePercentage] = useState(0);

    const { scrolling } = useContext(ScrollingContext);

    const handleScrolling = () => {
        const scrollTop = getCurrentScrollingPosition();
        const triangleContainer = document.querySelector(
            ".home__wise-1 .home-wise-triangle__contianer"
        );
        const containerHeight = triangleContainer.getBoundingClientRect().height;
        const offsetTop = triangleContainer.getBoundingClientRect().top + scrollTop;

        const sConstant = (containerHeight * 2) / 3;
        const eConstant = (window.innerHeight - containerHeight) / 2 - 50;

        const { percentage } = getScrollingPercentage(
            scrollTop,
            offsetTop,
            containerHeight,
            sConstant,
            eConstant,
            false
        );
        setToUsePercentage(percentage);
    };

    const getTextPercentage = () => {
        const textEndPoint = 0.6;

        const textPercentage =
            (toUsePercentage >= textEndPoint ? textEndPoint : toUsePercentage) / textEndPoint;
        return 1 - textPercentage;
    };
    const toTransform = 90;

    useEffect(() => {
        handleScrolling();
    }, [scrolling]);

    return (
        <section className="home__wise relative home__wise-1 py-5">
            <div className="home-wise__rect"></div>
            <div className="home-wise__rect"></div>
            <Container className="">
                <div className="home-wise__container flex-center-both">
                    <div className="home-wise-triangle__contianer w-full max-w-lg">
                        <svg
                            className="home-wise__triangle"
                            viewBox="0 0 600 330"
                            style={{
                                "--triangle-gradient-1": `rgb(${toUsePercentage * 255},${
                                    getTextPercentage() * 200 + 29
                                },${getTextPercentage() * 180 + 72})`,
                                "--triangle-gradient-2": `rgb(${
                                    toUsePercentage * 147
                                },${51},${234})`,
                            }}
                        >
                            <defs>
                                <linearGradient id="linear-gradient">
                                    <stop offset="0" stopColor="var(--triangle-gradient-1)" />
                                    <stop offset="1" stopColor="var(--triangle-gradient-2)" />
                                </linearGradient>
                            </defs>
                            <path
                                style={{ strokeDashoffset: `${924.9 * (1 - toUsePercentage)}` }}
                                className="triangle smooth-fast"
                                d="M191.9 118.2 128 7.3h335.8L295.9 298l-62.7-108.5"
                            />
                            <path
                                className="hide smooth"
                                d="M170 112.9h93v82h-93zM370 43.9h93v82h-93z"
                            />
                            <g
                                className="triangle-text__container-1"
                                style={{
                                    transform: `translateX(${
                                        getTextPercentage() * toTransform * -1
                                    }px)`,
                                }}
                            >
                                <text
                                    x={540}
                                    y={90}
                                    className="triangle-text triangle-text-1 smooth"
                                    data-svg-origin="239.28125 51.703125"
                                    transform="translate(-28.133)"
                                >
                                    {
                                        "\u201D\u0645\u0627 \u0627\u0644\u0646\u062C\u0627\u062D \u0625\u0644\u0627 \u0644\u062D\u0638\u0629 "
                                    }
                                </text>
                            </g>
                            <g
                                className="triangle-text__container-2"
                                style={{
                                    transform: `translateX(${getTextPercentage() * toTransform}px)`,
                                }}
                            >
                                <text
                                    x={300}
                                    y={160}
                                    className="triangle-text triangle-text-2 smooth"
                                    data-svg-origin="-0.71875 121.53125"
                                    transform="translate(24.264)"
                                >
                                    {
                                        "\u062A\u0647\u0632\u0645 \u0641\u064A\u0647\u0627 \u0634\u0647\u0648\u0629\u201C"
                                    }
                                </text>
                            </g>
                        </svg>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Wise1Section;
