import React from "react";

import FlexRowReverse from "../../components/ui/FlexRowReverse";

import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
import Noise from "../../assets/Noise.png";
import Einstein from "../../assets/Einstein-about.png";
const AboutSection = () => {
    const token = auth.getToken();
    return (
        <section className=" w-full h-auto z-10 bg-midNight-900 dark:bg-midNight-950 smooth relative overflow-hidden">
            <div
                className="right-0 top-0 bottom-0 h-full w-full -z-10 absolute opacity-10 dark:opacity-5 smooth"
                style={{
                    backgroundImage: "url(" + Noise + ")",
                    backgroundSize: "contain",
                    backgroundPosition: "center center",
                    backgroundRepeat: "repeat-both",
                }}
            ></div>

            <div className="home-secondary__inner-container px-4  h-full flex justify-center overflow-hidden">
                <div className="container h-full ">
                    <div className="flex flex-col-reverse lg:flex-row h-full justify-between ">
                        <div className="flex-1 relative h-full pt-0 lg:pt-12">
                            <img className="" src={Einstein} alt="logo" />
                        </div>

                        <div className="flex-1 flex-center-both pt-md-0 pt-3 pb-3">
                            <div className=" py-20 lg:py-0 flex items-center md:items-start flex-col">
                                <h1 className="text-3xl font-vip sm:text-5xl font-Vip flex items-center text-[#C7CD8E] gap-3">
                                    ليه اينشتاين
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={103}
                                        height={34}
                                        fill="none"
                                    >
                                        <path
                                            fill="#C3D14F"
                                            fillRule="evenodd"
                                            d="M6.227 28.21c.211.103 1.281.688 2.38 1.293 4.283 2.356 6.882 3.638 7.566 3.728.541.08.964-.117 1.294-.589.379-.542.297-1.107-.254-1.792-.463-.578-.629-.724-3.738-3.604-1.123-1.04-2.084-1.952-2.143-2.038-.06-.087.87.145 2.074.495a46.921 46.921 0 0 0 17.175 1.802c2.27-.19 7.136-1.058 9.119-1.627 4.304-1.261 8.354-3.269 12.356-6.146.79-.553 1.47-.99 1.51-.961.05.035.582.512 1.195 1.046 3.644 3.224 8.618 5.018 14.3 5.14 5.869.133 10.713-1.109 18.324-4.703 7.605-3.584 12.677-7.521 14.34-11.155.772-1.657.751-2.673-.057-3.269-.554-.403-.728-.24-.409.371.316.595.318.955-.027 1.746-1.74 4.043-8.634 8.767-18.48 12.65-5.151 2.044-8.92 2.797-13.438 2.698-5.214-.108-9.745-1.681-13.126-4.557-1.529-1.294-1.476-1.093-.5-2.039 3.831-3.7 6.204-8.496 5.83-11.762C61.038.953 56.925-1.24 53.616.733c-1.463.874-2.853 2.816-3.438 4.783-.91 3.114-.846 5.88.21 8.533.376.936 1.33 2.605 1.59 2.787.22.155.029.364-1.007 1.119-2.721 1.965-6.014 3.83-8.612 4.851-4.256 1.684-10.266 2.797-15.2 2.825-4.243.019-8.58-.551-12.446-1.644-1.738-.485-4.843-1.598-4.877-1.741 0-.044.525-.26 1.17-.497 3.385-1.186 5.777-2.725 7.393-4.733.885-1.114 1.094-1.52.765-1.542-.07-.005-.727.313-1.459.697-1.926 1.044-6.387 2.464-12.45 3.974-1.195.299-2.443.695-2.836.914-1.564.847-1.889 2.25-.847 3.622.31.41.846.861 2.343 1.924 1.058.771 2.108 1.491 2.313 1.605Zm-1.928-4.46c-.002-.06.381-.18.858-.265l.87-.154 1.41 1.466c.782.802 1.605 1.632 1.835 1.838.505.473.7.834.325.602-.14-.099-.86-.499-1.602-.913-1.294-.712-3.657-2.352-3.696-2.573Zm48.276-9.26c-1.296-2.418-1.507-5.062-.652-7.99 1.048-3.587 3.777-5.308 6.007-3.777 1.17.804 1.666 2.228 1.366 3.916-.281 1.597-.887 2.996-2.165 4.927-1.087 1.66-1.716 2.43-3.09 3.816l-.545.544-.28-.39a10.875 10.875 0 0 1-.642-1.047Z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    ؟
                                </h1>
                                <FlexRowReverse className="font-h1 pt-6 flex-col gap-10 items-center md:items-start">
                                    <p className="text-[#EAEBDE] text-lg lg::text-2xl w-full lg:w-2/3">
                                        لأن طلبة كتير بتواجه صعوبة في فهم الفيزياء وحل المسائل
                                        وفرتلك في منصة اينشتاين كل الي نفسك فيه عشان اخليلك المادة
                                        بسيطة ومُمتعة{" "}
                                    </p>
                                    <div className="flex">
                                        <Button
                                            color=""
                                            className="scale text-midNight-950 py-3 px-6 text-xl border-none bg-Olive-400 font-vip "
                                            element="Link"
                                            to={`${token ? "/me/user/subscriptions" : "/register"}`}
                                        >
                                            {token ? "تابع اشتراكك" : "اشترك الأن"}
                                        </Button>
                                    </div>
                                </FlexRowReverse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="font-vip dark:opacity-30 smooth text-7xl text-transparent text-stroke absolute -top-4 left-[10%]  -z-10">
                Phyiscs
            </p>
            <p className="font-vip text-7xl text-[#606341] absolute -bottom-7 left-[10%] -z-10">
                Society
            </p>
        </section>
    );
};

export default AboutSection;
