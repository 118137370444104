export const lerp = (a, b, u) => {
    return (1 - u) * a + u * b;
};
export const changeGradient = (percentage, rgb, degree = -45) => {
    return `linear-gradient(${degree}deg, rgb(${lerp(
        rgb[0][0][0],
        rgb[1][0][0],
        percentage
    )},${lerp(rgb[0][0][1], rgb[1][0][1], percentage)},${lerp(
        rgb[0][0][2],
        rgb[1][0][2],
        percentage
    )}), rgb(${lerp(rgb[0][1][0], rgb[1][1][0], percentage)},${lerp(
        rgb[0][1][1],
        rgb[1][1][1],
        percentage
    )},${lerp(rgb[0][1][2], rgb[1][1][2], percentage)}))`;
};
