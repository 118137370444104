import React from "react";
import Categorie from "../../sections/home/CoursesSection/Categorie";

const UserCourses = () => {
    return (
        <div className="relative mb-20 overflow-hidden" id="courses">
            <Categorie
                api={`/api/sellables/subscribed`}
                grid="small"
                noCoursesPlaceholder="انت غير مشترك بأي كورس حتى الآن!"
            />
        </div>
    );
};

export default UserCourses;
