import { Icon } from "@iconify/react";
import React from "react";

import FooterLink from "./components/FooterLink";
import Logo from "../../assets/EinsteinLogo.png";
import Noise from "../../assets/Noise.png";
const Footer = () => {
    return (
        <div className="footer relative bg-midNight-950 py-14 clr-white flex-center-both flex-col space-y-6 w-full">
            <div
                className="right-0 top-0 bottom-0 h-full w-full z-0 absolute opacity-20 dark:opacity-10 smooth"
                style={{
                    backgroundImage: "url(" + Noise + ")",
                    backgroundSize: "contain",
                    backgroundPosition: "center center",
                    backgroundRepeat: "repeat-both",
                }}
            ></div>
            <div className="flex-center-both space-y-8 flex-col">
                <img src={Logo} alt="logo##" className=" max-w-[300px]  -mt-10" />
                <div className="flex-center-both space-x-5 space-x-reverse">
                    <FooterLink
                        link="https://www.facebook.com/groups/eba4physea2i"
                        className={`bg-gradient-to-r from-cyan-500 to-sky-400`}
                        icon={<Icon icon="el:group" />}
                    />
                    <FooterLink
                        link="https://www.youtube.com/channel/UCJVp97ImKXvNpfcFYHHMwDQ"
                        className={`bg-gradient-to-r from-rose-500 to-red-500 `}
                        icon={<Icon icon="ant-design:youtube-filled" />}
                    />
                    <FooterLink
                        link="https://www.facebook.com/Physics.SocietYf0t1"
                        className={`bg-gradient-to-r from-cyan-500 to-blue-500 `}
                        icon={<Icon icon="fa6-brands:facebook" />}
                    />
                    <FooterLink
                        link="https://www.facebook.com/Physics.SocietYf0t1"
                        className={`bg-gradient-to-r from-black to-slate-900 `}
                        icon={<Icon icon="fa6-brands:tiktok" />}
                    />
                </div>
            </div>
            <div className="h-1 bg-slate-800 rounded-md w-2/3 sm:w-1/3 "></div>
            <div className="flex-center-both space-x-5 space-x-reverse px-5">
                <span className="font-h2 ">
                    <Icon icon="emojione-v1:beating-heart" />
                </span>
                <span className="text-slate-200 text-center shrink">
                    تم صنع هذه المنصة بهدف تهيئة الطالب لـ كامل جوانب الثانوية العامة و ما بعدها
                </span>
                <span className="font-h2">
                    <Icon icon="emojione-v1:beating-heart" />
                </span>
            </div>
            <div className="en text-center font-com space-x-2 opacity-70 px-5 flex flex-wrap flex-center-both">
                <span className="font-w-bold space-x-1">
                    <span className="text-Olive-200">&#60;</span>
                    <span className="text-Olive-300">Developed By</span>
                    <span className="text-Olive-200">&#62;</span>
                </span>
                <span>
                    <a
                        href="https://www.facebook.com/Om4rS4Ieh/"
                        className="bg-midNight-700 hover-shadow smooth px-3 py-1 rounded-md"
                    >
                        Omar
                    </a>
                    <span>,</span>
                    <a
                        href="https://www.facebook.com/emad.sharf.16"
                        className="bg-midNight-700 hover-shadow smooth px-3 py-1 rounded-md"
                    >
                        Emad
                    </a>
                </span>
                <span className="font-w-bold space-x-1">
                    <span className="text-Olive-200">&#60;</span>
                    <span className="text-Olive-300">
                        All Copy Rights Reserved @{new Date().getFullYear()}
                    </span>
                    <span className="text-Olive-200">&#62;</span>
                </span>
            </div>
            <div className="en">
                Powered by اي فور
                <br />
                ت.ض. 723780153
            </div>
            {/* <div className="text"></div> */}
        </div>
    );
};

export default Footer;
