import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "magdy-mm-admin";
export const domainName = "mahmoud-magdy.com";
export const isManualPayment = false;
export const isCouponable = true;
export const isMultiYear = false;
export const isCodes = true;
export const isBtns = true;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "blue";
export const headerSectionColor = "rose";
export const navbarIconColor = "text-rose-500";
export const navbarDisclosureColor = "rose";

export const progressBarColor = "teal";
export const loadingBarColor = "teal";

export const isBunny = false;

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = true;
export const prepaidInfoColor = "rose";

export const autoChangeRandomNames = true;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "Olive";
export const registerPageColor = "midNight";

export const isAnalytics = false;
export const isAbleChangingPassword = false;

export const isMultiAdmin = true;

export const isSubjectSubscriptionable = false;

export const isSendSmsMessages = true;

export const isAccountCreationRequest = false;

export const showExamStatisticsTable = true;

export const isCategories = true;

export const isRemotePlatformIntegrationEnabled = true;
