import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import AdminContainer from "../../components/ui/AdminContainer";
import RemoteTable from "../../components/ui/RemoteTable";
import { printFullDate } from "../../utils/time";
import { handleInputChange } from "../../services/formServices";
import Button from "../../components/ui/Button";

const placeholders = {
    pending: "في انتظار الإرسال",
    cancelled: "تم إالغاء الإرسال",
    sent: "تم الإرسال بنجاح",
    api_error: "حدث خطأ في الإرسال",
    sms_error: "يوجد خطأ في الرساله",
};

const SmsMessagesTable = () => {
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "الاسم الطالب",
            reorder: true,
            selector: (row) => (row.user ? row.user.full_name : ""),
        },
        {
            name: "رقم هاتف الطالب",
            reorder: true,
            selector: (row) => (row.user ? "0" + row.user.phone : ""),
        },
        {
            name: "حالة الرسالة",
            reorder: true,
            selector: (row) => (
                <div
                    className={`rounded-md px-3 py-1 w-full text-white ${
                        row.message_status === "pending"
                            ? "bg-yellow-500"
                            : row.message_status === "sent"
                            ? "bg-green-500"
                            : "bg-rose-500"
                    }`}
                >
                    {placeholders[row.message_status]}
                </div>
            ),
            sortable: true,
            sortField: "message_status",
        },
        {
            name: "رقم الهاتف اللي اتبعت عليه الرسالة",
            reorder: true,
            selector: (row) => row.phone_number,
            sortable: true,
            sortField: "phone_number",
        },
        {
            name: "محتوى الرسالة",
            reorder: true,
            selector: (row) => row.message_content,
            sortable: true,
            sortField: "message_content",
        },
        {
            name: "طول الرسالة",
            reorder: true,
            selector: (row) => row.message_length,
            sortable: true,
            sortField: "message_length",
        },
        {
            name: "اسم الكامبين",
            reorder: true,
            selector: (row) => row.campaign_name,
            sortable: true,
            sortField: "campaign_name",
        },
        // {
        //     name: "تاريخ الإرسال",
        //     reorder: true,
        //     selector: (row) => row.request_sent_at,
        //     format: (row) => printFullDate(row.request_sent_at),
        //     sortable: true,
        //     sortField: "request_sent_at",
        // },

        // {
        //     name: "تاريخ انشاء طلب الإرسال",
        //     reorder: true,
        //     selector: (row) => row.created_at,
        //     format: (row) => printFullDate(row.created_at),
        //     sortable: true,
        //     sortField: "created_at",
        // },
    ];

    const initialState = {
        phone: "",
        name: "",
    };

    const [filterData, setFilterData] = useState(initialState);

    const [data, setData] = useState(initialState);

    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="phone"
                        placeholder="رقم الهاتف"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="name"
                        placeholder="اسم الكامبين"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                >
                    بحث
                </Button>
                <RemoteTable
                    api={`/api/sms_messages/paginate`}
                    columns={columns}
                    filterData={filterData}
                />
            </div>
        </AdminContainer>
    );
};

export default SmsMessagesTable;
