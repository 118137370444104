import React, { useContext } from "react";
import SubjectTeacherContext from "../../context/SubjectTeacherContext";
import HeaderSection from "../../components/ui/HeaderSection";
import CourseItemsCounter from "../../components/items/CourseItemsCounter";
import { headerSectionColor } from "../../services/defaultSettings";
import { getYearPlaceHolder } from "../../services/yearSevices";
import { isObjectEmpty } from "../../utils/objects";

const SubjectTeacherHeaderSection = () => {
    const { subjectTeacher } = useContext(SubjectTeacherContext);

    return (
        <HeaderSection>
            {isObjectEmpty(subjectTeacher) ? (
                ""
            ) : (
                <div className="relative z-10 space-y-6">
                    {/* <div className="flex-center-both text-slate-100 font-h2 font-w-bold underline">
                        صفحة المدرس
                </div> */}
                    <div className="flex flex-wrap">
                        <CourseItemsCounter
                            text="كورسات ضمن الاشتراك الشهري"
                            number={subjectTeacher.courses_count}
                            icon="carbon:course"
                            className={`${
                                headerSectionColor === "rose"
                                    ? "bg-yellow-300 text-slate-900"
                                    : "bg-rose-500 text-slate-100"
                            }`}
                            iconClassName="text-yellow-400"
                        />
                    </div>
                    <div className="space-y-2">
                        <div className="font-h1 font-w-bold">
                            {subjectTeacher.teacher.name} - {subjectTeacher.subject.name}
                        </div>
                    </div>
                    <div className="space-y-2">
                        <div className="font-h2 text-slate-200">
                            {getYearPlaceHolder(subjectTeacher.subject.year)}
                        </div>
                    </div>
                </div>
            )}
        </HeaderSection>
    );
};

export default SubjectTeacherHeaderSection;
