import React, { useState } from "react";
import { Link } from "react-router-dom";
import { printUnit } from "../../utils/ar";
import { getYearPlaceHolder } from "../../services/yearSevices";
// import colors from 'tailwindcss/colors';
import { subjectCardColors } from "../../services/contentServices";

const SubjectCard = ({
    name = "الكورس الاول - تيست",
    price = "30",
    isNew = false,

    id,
    teachers_count,
    courses_count,
    teachers,
    year,
    color,
    ...props
}) => {
    const [subscribedToOne, setSubscribedToOne] = useState(false);

    return (
        <div className="group">
            <div className="relative z-10">
                <div
                    className={`rounded-md w-full bg-third-container clr-text-primary px-2 py-2 shadow-large--oblique hover-shadow-larg group-hover:shadow-large smooth ${
                        !subscribedToOne
                            ? `border ${subjectCardColors["borderColor"][color]}` // borderColor
                            : "border-4 border-cyan-500"
                    }`}
                >
                    <div className="flex flex-col space-y-3">
                        <div className="flex flex-col space-y-2 w-full">
                            <div className="flex-center-both">
                                <div
                                    className={`font-w-bold font-h3 flex-center-both ${
                                        subscribedToOne
                                            ? "bg-cyan-400 dark:bg-cyan-900"
                                            : subjectCardColors["bgColor"][color] // bgColor
                                    } rounded-md py-2 px-6 smooth clr-text-primary`}
                                >
                                    {name}
                                </div>
                            </div>
                            <div className="flex-center-both">
                                <div
                                    className={`font-smaller flex-center-both opacity-75 ${
                                        subscribedToOne
                                            ? "bg-cyan-400 dark:bg-cyan-900 border border-cyan-700 dark:border-cyan-300"
                                            : subjectCardColors["borderColor2"][color] // borderColor2
                                    } rounded-full pb-0.5 px-2 smooth dark:text-slate-300 text-slate-800`}
                                >
                                    {getYearPlaceHolder(year)}
                                </div>
                            </div>
                            <div className="px-5">
                                <div
                                    className={`divider-2 rounded-lg smooth ${
                                        !subscribedToOne
                                            ? subjectCardColors["bgColor"][color] // bgColor
                                            : "bg-sky-500"
                                    }`}
                                ></div>
                            </div>
                            {/* <div className="clr-text-secondary">
                                    {formatDescription(description)}
                                </div> */}
                        </div>
                        <div className="flex w-full">
                            <div className="font-smaller flex flex-row justify-evenly w-full">
                                <>
                                    <Link
                                        to={`/subject/${id}`}
                                        className={`border-2 ${
                                            subscribedToOne
                                                ? "border-cyan-500 hover:bg-cyan-500 hover:text-white"
                                                : subjectCardColors["borderHover"][color] // borderHover
                                        } rounded-full px-3 py-1  smooth clr-text-primary`}
                                    >
                                        تفاصيل..
                                    </Link>
                                </>
                            </div>
                        </div>
                        <div className="px-5">
                            <div
                                className={`divider-2 rounded-lg smooth ${
                                    !subscribedToOne
                                        ? subjectCardColors["bgColor"][color] // bgColor
                                        : "bg-sky-500"
                                }`}
                            ></div>
                        </div>
                        <div className="flex justify-between pt-2 font-small w-full">
                            <div className="space-y-2">
                                <div className="text-center font-smaller">- عدد المدرسين -</div>
                                <div
                                    className={`rounded-full ${
                                        subscribedToOne
                                            ? " bg-cyan-500 dark:bg-cyan-700 border-cyan-600"
                                            : subjectCardColors["countState"][color] // countState
                                    } font-w-bold px-2 pb-0.5 smooth border-2 clr-text-primary text-center`}
                                >
                                    {printUnit(teachers_count, "مدرس")}
                                </div>
                            </div>
                            <div className="space-y-2">
                                <div className="text-center font-smaller">- عدد الكورسات -</div>
                                <div
                                    className={`rounded-full ${
                                        subscribedToOne
                                            ? " bg-cyan-500 dark:bg-cyan-700 border-cyan-600"
                                            : subjectCardColors["countState"][color] // countState
                                    } font-w-bold px-2 pb-0.5 smooth border-2 clr-text-primary text-center`}
                                >
                                    {printUnit(courses_count, "كورس")}
                                </div>
                            </div>
                        </div>
                        <div className="flex-col flex space-y-3">
                            <div className="px-10">
                                <div className="divider-1 bg-secondary-container smooth"></div>
                            </div>
                            <div className="w-full flex flex-col space-y-4  flex-center-both px-2">
                                {teachers.map((subject) => (
                                    <React.Fragment key={subject.id}>
                                        {subject.subscriptions_count > 0 && !subscribedToOne
                                            ? setSubscribedToOne(true)
                                            : ""}
                                        <div
                                            className={`font-smaller clr-text-primary rounded-md border-2 px-2 py-1 smooth bg-opacity-100 w-full ${
                                                subject.subscriptions_count > 0
                                                    ? "bg-cyan-400 border-cyan-500 dark:bg-cyan-900 dark:border-cyan-700"
                                                    : subjectCardColors["cardState"][color] // cardState
                                            }`}
                                        >
                                            <div className="font-w-bold text-center">
                                                {subject.name}
                                            </div>
                                            <div
                                                className={`divider-2 rounded-lg smooth my-2 ${
                                                    subject.subscriptions_count > 0
                                                        ? "bg-cyan-500 dark:bg-cyan-800"
                                                        : `bg-${color}-500 dark:bg-${color}-800` // bgColor2
                                                }`}
                                            ></div>
                                            <div className="text-center font-w-bold">
                                                الاشتراك الشهري :{" "}
                                            </div>
                                            {subject.subscriptions_count < 1 ? (
                                                <div className="flex-center-both">
                                                    <Link
                                                        to={`/subject_teacher/teacher/${subject.id}/subject/${id}`}
                                                    >
                                                        <div
                                                            className={`${subjectCardColors["teacherDetail"][color]}`}
                                                        >
                                                            {" "}
                                                            {/* Teacher Detail */}
                                                            {/* bg-opacity-100 dark:bg-opacity-100  hover:bg-opacity-0 */}
                                                            تفاصيل...
                                                        </div>
                                                    </Link>
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            {/* <div className=" divider-2 rounded-lg smooth bg-yellow-500 dark:bg-yellow-800 my-2"></div> */}
                                            <div className="flex justify-between pt-2">
                                                {subject.subscriptions_count > 0 ? (
                                                    <>
                                                        <Link
                                                            to={`/subject_teacher/teacher/${subject.id}/subject/${id}`}
                                                        >
                                                            <div
                                                                className={`${subjectCardColors["teacherDetail"][color]}`}
                                                            >
                                                                {" "}
                                                                {/* access state */}
                                                                {/* bg-opacity-100 dark:bg-opacity-100  hover:bg-opacity-0 */}
                                                                الدخول ..
                                                            </div>
                                                        </Link>
                                                        <div className="rounded-full bg-cyan-500 dark:bg-cyan-700 border-2 border-cyan-600 font-w-bold px-2 pb-0.5 smooth clr-text-primary">
                                                            انت مشترك !
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="rounded-full bg-cyan-500 dark:bg-cyan-700 border-2 border-cyan-600 font-w-bold px-2 pb-0.5 smooth clr-text-primary">
                                                            {printUnit(subject.pivot.price, "جنيه")}
                                                        </div>
                                                        <Link
                                                            to={`/subject_teacher/teacher/${subject.id}/subject/${id}/subscribe`}
                                                        >
                                                            <div
                                                                className={`${subjectCardColors["accessState"][color]}`}
                                                            >
                                                                {" "}
                                                                {/* access state */}
                                                                {/* bg-opacity-100 dark:bg-opacity-100  hover:bg-opacity-0 */}
                                                                اشترك الآن
                                                            </div>
                                                        </Link>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* </Link> */}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubjectCard;
