import React, { useContext, useState } from "react";
import SubjectTeacherContext from "../../context/SubjectTeacherContext";
import { isObjectEmpty } from "../../utils/objects";
import { baseURL } from "../../config";
import CourseDescItem from "../../components/items/CourseDescItem";
import { printUnit } from "../../utils/ar";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
import { useLocation, useNavigate } from "react-router-dom";
import modal from "../../services/modalServices";
import CourseItemsCounter from "../../components/items/CourseItemsCounter";
import { getYearPlaceHolder } from "../../services/yearSevices";

const SubjectTeacherInfoSection = () => {
    const token = auth.getToken();
    const { subjectTeacher } = useContext(SubjectTeacherContext);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    let location = useLocation();

    return (
        <>
            {!isObjectEmpty(subjectTeacher) && (
                <div className="w-full glassy smooth clr-text-primary shadow-large rounded-lg overflow-hidden">
                    <div className="p-4 space-y-8">
                        <div className="overflow-hidden rounded-md">
                            <img
                                src={`${baseURL}/${subjectTeacher.teacher.picture}`}
                                alt="course"
                            />
                        </div>

                        {parseInt(subjectTeacher.price) === 0 ? (
                            <>
                                <Button color="rose" className="w-full inline-block text-center">
                                    هذا الكورس مجاني !
                                </Button>
                            </>
                        ) : (
                            <>
                                {token ? (
                                    subjectTeacher.subscriptions_count > 0 ? (
                                        <Button
                                            color="cyan"
                                            className="w-full inline-block text-center"
                                        >
                                            انت مشترك بهذه المادة !
                                        </Button>
                                    ) : subjectTeacher.sellable ? (
                                        <>
                                            <div className="flex-center-both">
                                                <CourseItemsCounter
                                                    text="جنيهًا"
                                                    number={subjectTeacher.price}
                                                    isIncreasing={false}
                                                    icon="ant-design:pound-circle-twotone"
                                                    counterClassName="bg-blue-600"
                                                    className="bg-yellow-500 text-slate-100 ml-0"
                                                    iconClassName="text-yellow-400"
                                                />
                                            </div>
                                            <Button
                                                element="Link"
                                                to={`subscribe/previous_invoices/`}
                                                color="blue"
                                                className="w-full inline-block text-center"
                                            >
                                                اشترك الآن !
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            color="yellow"
                                            className="w-full inline-block text-center"
                                        >
                                            هذه المادة غير قابلة للإشتراك الآن
                                        </Button>
                                    )
                                ) : (
                                    <>
                                        <div className="flex-center-both">
                                            <CourseItemsCounter
                                                text="جنيهًا"
                                                number={subjectTeacher.price}
                                                isIncreasing={false}
                                                icon="ant-design:pound-circle-twotone"
                                                counterClassName="bg-blue-600"
                                                className="bg-yellow-500 text-slate-100 ml-0"
                                                iconClassName="text-yellow-400"
                                            />
                                        </div>
                                        <Button
                                            onClick={() => {
                                                modal.message({
                                                    title: `يجب عليك تسجيل الدخول اولًا`,
                                                    text: "يجب عليك تسجيل الدخول لشراء الكورس",
                                                    icon: "warning",
                                                    button: "تسجيل الدخول",
                                                    callback: (accept) => {
                                                        if (accept) {
                                                            navigate("/login", {
                                                                state: {
                                                                    prevPath: location.pathname,
                                                                },
                                                            });
                                                        }
                                                    },
                                                });
                                            }}
                                            color="blue"
                                            className="w-full inline-block text-center"
                                        >
                                            اشترك الآن !
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                        <div className="space-y-2">
                            <Button
                                element="Link"
                                to={`/subject/${subjectTeacher.subject.id}`}
                                color="yellow"
                                className="w-full inline-block text-center"
                            >
                                صفحة {subjectTeacher.subject.name} -{" "}
                                {getYearPlaceHolder(subjectTeacher.subject.year)}
                            </Button>
                            <Button
                                element="Link"
                                to={`/teacher/${subjectTeacher.teacher.id}`}
                                color="purple"
                                className="w-full inline-block text-center"
                            >
                                صفحة / {subjectTeacher.teacher.name}
                            </Button>
                        </div>

                        <div className="font-small">
                            <CourseDescItem
                                title="عدد الكورسات"
                                value={`+ ${subjectTeacher.courses_count}`}
                                valueName={
                                    printUnit(subjectTeacher.courses_count, "كورس").split(" ")[1]
                                }
                                icon="ant-design:question-circle-twotone"
                                isLast={true}
                            />
                            {/* <div className="py-4 space-y-2">
                                <SubscriptionInstructionText isSubject={false} />
                            </div> */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SubjectTeacherInfoSection;
