import React, { useEffect, useState } from "react";

import { ScrollingProvider } from "../../../context/ScrollingContext";

import SectionHead from "../../../components/ui/SectionHead";

// import year1 from "../../../../assets/year-2.jpeg";
// import Waves from "../../../components/ui/Waves";
import Categorie from "./Categorie";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import LoadingIcon from "./../../../components/ui/LoadingIcon";

const CoursesSection = ({ title = false }) => {
    // const { token } = useContext(AuthContext);

    const [categories, setCategories] = useState([]);

    const getCategories = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get("/api/categories_info", config);
        setCategories(data);
    };

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <div className="my-[10vh] relative mb-20 overflow-hidden" id="courses">
            {/* <ScrollingProvider>
                <SectionHead title={title ? title : "الكورسات"} />
            </ScrollingProvider> */}

            {categories.length > 0 ? (
                categories.map((c) =>
                    c.courses.length ? (
                        <Categorie
                            key={c.id}
                            id={c.id}
                            count={15}
                            titleFirst={c.titleFirst}
                            titleLast={c.titleLast}
                        />
                    ) : null
                )
            ) : (
                <div className="text-center">
                    <LoadingIcon className="text-4xl text-blue-700 mt-20 mb-5" /> <br />
                    <span className="text-xl">جاري تحميل الكورسات</span>
                </div>
            )}
            {/* <Categorie titleFirst="كل" titleLast="الكورسات" api={`/api/sellables`} /> */}

            {/* <Waves className="fill-rose-200" /> */}
            {/* <div className="py-16 bg-rose-200"></div> */}
        </div>
    );
};

export default CoursesSection;
