import React from "react";
import SubscriptionInstructionText from "./SubscriptionInstructionText";
// import SubscriptionInstructionTexts from "../../../../../../Movies/SubscriptionInstructionTexts";

const SubscriptionInstructionComponent = ({ isSubject = false }) => {
    return (
        <div className="p-4 space-y-8 font-h3">
            <div className="flex ">
                <div className="py-2 px-5 font-w-bold font-h1 bg-yellow-500 bg-opacity-50 rounded-md">
                    طريقة الاشتراك :
                </div>
            </div>
            <SubscriptionInstructionText isSubject={isSubject} />
        </div>
    );
};

export default SubscriptionInstructionComponent;
