import React, { useState } from "react";
import Electric from "../../components/svgs/Electric";

import "./MainSection.css";

import Button from "../../components/ui/Button";
import auth from "../../services/authServices";

const MainSection = ({ title = false }) => {
    const token = auth.getToken();

    const [transforms, setTransforms] = useState({
        right: "",
        left: "",
    });
    // const buttonColorClass = token ? {} : { color: "rose" };
    return (
        <>
            <section className="home-main__section relative negative-nav-margin">
                <div className="opacity-30">
                    <div className="absolute right-10 top-1/4 w-2 h-20 bg-midNight-900 rounded-full"></div>
                    <div className="absolute right-20 top-1/3 w-1 h-32 bg-midNight-900 rounded-full"></div>
                    <div className="absolute right-32 top-1/2 w-1 h-32 bg-midNight-700 rounded-full"></div>
                    <div className="absolute left-10 top-1/3 w-2 h-10 bg-midNight-800 rounded-full"></div>
                    <div className="absolute left-20 top-1/2 w-1 h-36 bg-[#064556] rounded-full"></div>
                    <div className="absolute left-32 top-2/3 w-1 h-36 bg-midNight-700 rounded-full"></div>
                    <div className="absolute left-40 top-1/4 w-1 h-36 bg-[#064556] rounded-full"></div>
                </div>

                <Electric />
                <div className="home-main__container flex-center-both">
                    <div className="home-main__content text-center flex flex-col gap-6">
                        <h1 className="text-4xl sm:text-6xl font-Sharp-Grotesk home-main__title font-semibold">
                            <div className="relative z-10 font-grot text-Olive-400">
                                <span className="text-midNight-900 smooth dark:text-[#D5EAF0]">
                                    Physics{" "}
                                </span>{" "}
                                Society
                            </div>
                            <div className="mt-6 flex justify-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-[220px] sm:w-[440px] h-[18px]"
                                    viewBox="0 0 445 18"
                                    fill="none"
                                >
                                    <path
                                        d="M0.839746 9L9.5 17.6603L18.1603 9L9.5 0.339746L0.839746 9ZM444.16 9L435.5 0.339746L426.84 9L435.5 17.6603L444.16 9ZM9.5 10.5H435.5V7.5H9.5V10.5Z"
                                        fill="url(#paint0_linear_38_1523)"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_38_1523"
                                            x1="32.0002"
                                            y1="-17.002"
                                            x2="366.5"
                                            y2="23.9996"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#064556" />
                                            <stop
                                                offset="1"
                                                stopColor="#C3D14F"
                                                stopOpacity="0.31"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                        </h1>

                        <div className="text-lg md:text-2xl font-lama home-main__text  mt-6">
                            <p>منصة هتحببك وتميزك في الفيزياء</p>
                            <p>
                                مع{"  "}
                                <span className="text-Olive-500 font-w-bold inline-block">
                                    م/ محمود مجدي
                                </span>
                            </p>

                            <div className="flex mt-3 space-x-reverse space-x-5 flex-center-both">
                                <Button
                                    hoverEffect="border-Olive-300"
                                    className=" text-sm md:text-xl border-Olive-400 text-black smooth hover:shadow-lg bg-Olive-400"
                                    element="Link"
                                    to={`${token ? "/me/user/courses" : "/register"}`}
                                >
                                    {token ? "كورساتي" : "انضم لينا"}
                                </Button>
                                <Button
                                    color=""
                                    className=" border-[#064556] text-sm md:text-xl bg-transparent clr-text-primary smooth hover:bg-midNight-900  hover:text-midNight-50  "
                                    element="a"
                                    href={`https://booxtore.net/`}
                                >
                                    اطلب كتابك الأن!{" "}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="arrow flex-center-both flex-col">
                    <span></span>
                    <span></span>
                    <span></span>
                    <div className="arrow-overlay p-a px-3 py-5"></div>
                </div>
            </section>
        </>
    );
};

export default MainSection;
